class Component {
    constructor(setup) {
        for (const [key, func] of Object.entries(setup || {})) {
            this[key] = func
        }
    }

    async preRender() {

    }

    async postRender() {
        
    }

    async render(options) {
        const self = this
        
        const html = await this.preRender(options)

        new MutationObserver(function(mutationsList) {
            this.disconnect()
            self.postRender(options)
        }).observe(document, {subtree: true, childList: true})

        return html
    }
}

const component = {
    create(setup) {
        return new Component(setup)
    },

    async prependHTML(element, html) {
        element.prepend(html)
    },

    async renderHTML(element, html) {
        element.html(html)
    },

    async appendHTML(element, html) {
        element.append(html)
    },
}

export default component