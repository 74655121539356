import { getCurrentUIOffset } from "./common-func.js"

const selection = {
    create: function(parent, element, options, pos, offsetx, offsety) {
        this.close(true)

        offsetx = offsetx || 0
        offsety = offsety || 0

        parent.append(`
            <div class="popup-selection">
            </div>
        `)

        const selection = $(".popup-selection").last()

        let displayedCount = 0
        for (let i = 0; i < options.length; i++) {
            const option = options[i]

            const canShow = option.canShow
            if (canShow && !canShow()) {
                continue
            }

            displayedCount++

            const button = `
                <div
                    class="popup-btn"
                    id="${option.id || ""}"
                    value="${option.value || "0"}"
                >
                    ${option.icon ? "<div class=\"popup-icon " + option.icon + "\"></div>" : ""}
                    <span class="popup-msg" >${option.name}</span>
                </div>
            `

            selection.append(button)

            if (option.callback) {
                $(".popup-btn").last().on("click", (e) => {
                    option.callback.call(this)
                    this.close()

                    e.stopPropagation()
                })
            }
        }

        if (displayedCount === 0) {
            selection.remove()
            return displayedCount
        }

        let left = 0,
            top = 0;
        
        const selectionPadding = 20 // px

        const selectionWidth = selection.outerWidth(),
            selectionHeight = selection.outerHeight(),
            screenWidthBoundary = $(window).width() - selectionWidth - selectionPadding,
            screenHeightBoundary = $(window).height() - selectionHeight - selectionPadding;

        const positionAtClicker = typeof element === "boolean"
        if (positionAtClicker) {
            left = window.mouse.x + 16
            top = window.mouse.y + 16
        } else {
            let [ox, oy] = getCurrentUIOffset(element)
            if (pos) {
                ox = 0
                oy = 0
            }
    
            const dataset = pos ? element.position() : element.offset()
    
            left = dataset.left + element.outerWidth() + offsetx - ox
            top = dataset.top + element.outerHeight(true) + offsety + oy
        }

        if (left > screenWidthBoundary) {
            left = screenWidthBoundary
        }

        if (top > screenHeightBoundary) {
            top = screenHeightBoundary
        }

        selection.css({
            "left": left + "px",
            "top": top + "px"
        })

        $("*").on("scroll", () => {
            selection.remove()
        })

        return displayedCount
    },

    close: function(instant) {
        const element = $(".popup-selection")

        if (instant) {
            element.remove()
            return
        }

        element.addClass("selection-discard")
        element.on("animationend", function() {
            $(this).remove()
        })
    },
}

export default selection