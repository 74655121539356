import hook from "./hook.js"
import timer from "./timer.js"

const menu = {
    create: function(title, secondary, permanent, onClose) {
        const struct = this
        const suffix = secondary ? "-2" : ""

        const body = $("body")
        const windowPanel = this.element(".window__panel", secondary)
        const popupWindow = this.element(".popup-window", secondary)
        const popupPanel = this.element(".panel__list", secondary)
        const closeButton = this.element("#window-close", secondary)

        const autosizeClass = "window-auto"
        const activeClass = "window-active"

        this.setTitle(title, secondary)
        
        if (this.lastButton) {
            windowPanel.off("click")
        }

        windowPanel.removeClass("window-huge " + autosizeClass)
        windowPanel.css({
            "transition": "",
            "transform": "",
            "opacity": ""
        })

        $(".hover-message").remove()

        popupWindow.addClass(activeClass)

        popupPanel.html("")
        popupPanel.removeClass("list-column")

        popupWindow.on("click", (event) => {
            event.stopPropagation()
        })
        
        if (body.css("overflow") !== "hidden") {
            body.attr("source-overflow", body.css("overflow"))
        }
        body.css("overflow", "hidden")

        if (permanent) {
            closeButton.remove()
        }

        timer.remove("windowClose")

        const returnToPage = () => {
            body.removeClass("no-overflow")
            body.css("overflow", body.attr("source-overflow"))  
        }
        
        closeButton.off().on("click", function() {
            if (onClose) {
                const result = onClose()

                if (!result) {
                    return
                }
            }

            const animDuration = 0.25

            windowPanel.css({
                "transition": `transform ${animDuration}s ease`,
                "transform": "translate(0%, 5%) scale(0.9)"
            })
            
            if (suffix === "" || !$(".popup-window").hasClass(activeClass)) {
                returnToPage()
            }

            struct.noUpdateState = false
            popupWindow.removeClass(activeClass)

            timer.create("windowClose", animDuration, 1, () => {
                windowPanel.removeClass(autosizeClass)
                popupPanel.html("")
            })

            hook.call("onWindowClosed")
        });
    },

    element(id, secondary) {
        return $(id + (secondary ? "-2" : ""))
    },

    close: function(secondary) {
        if (!this.isActive()) return
        this.element("#window-close", secondary).trigger("click")
    },

    setTitle: function(title, secondary) {
        this.element("#window-title", secondary).html(title)
    },

    setHTML: function(html, secondary) {
        const panel = this.element(".panel__list", secondary)
        panel.css("opacity", 0)
        panel.animate({
            opacity: 1
        }, 250)

        panel.html(html)
    },

    setRenderHTML: function(html, secondary) {
        this.setHTML(html, secondary)
    },

    setWindowClass: function(className, secondary) {
        this.element(".window__panel", secondary).addClass(className)
    },

    setPanelClass: function(className, secondary) {
        this.element(".panel__list", secondary).addClass(className)
    },

    isActive: function() {
        return $(".popup-window, .popup-window-2").hasClass("window-active")
    }
}

export default menu