import timer from "./timer.js"
import notify from "./notify.js"
import menu from "./menu.js"
import { v4 as uuidv4 } from "uuid"

const net = {
    cachedRequestData: {}
}

class Response {
    constructor(data) {
        for (const [key, value] of Object.entries(data)) {
            this[key] = value
        }
    }

    receive(onSuccess, codeHandlers = {}) {
        const code = this.code
        if (code === 200) {
            onSuccess()
        } else {
            const handler = codeHandlers[code]
            if (typeof handler === "string") {
                notify.error(handler, 10)
            } else if (typeof handler === "function") {
                handler()
            }
        }
    }
}

export function load(element, small, alter, instant) {
    const wrapper = $('.wrapper')

    if (typeof element === "undefined") {
        element = wrapper
    } else {
        element = typeof element === "string" ? $(element) : element
    }

    if (!element.attr("loader-id")) {
        element.attr("loader-id", uuidv4())
    }

    const timerId = "loader" + element.attr("loader-id")
    timer.remove(timerId)

    this.loading = true

    if (!element.attr("source-overflow")) {
        element.attr("source-overflow", element.css("overflow"))
    }

    const loaders = [
        `<div class="loader-overlay"></div>`,
    ]

    element.append(loaders[Math.round(Math.random() * (loaders.length - 1))])

    if (element.css("position") === "static") {
        element.css("position", "relative")
    }

    const loaderOverlay = $(".loader-overlay")

    if (element.is(wrapper)) {
        loaderOverlay.css("position", "fixed")
    } else {
        this.loadElement = element
    }

    if (small) {
        loaderOverlay.addClass("load-small")
    }

    loaderOverlay.on("click", function(event) {
        event.stopPropagation()
    })

    const delay = instant ? 0 : 0.5
    timer.create(timerId, delay, 1, () => {
        loaderOverlay.css("opacity", "1")
        
        timer.create(timerId, 40, 1, () => {
            this.finishLoad(element)
            notify.error("Request is not completed or was aborted by server!", 5)
        })
    })
}

net.load = load

export function finishLoad(parent) {
    if (typeof parent === "undefined") {
        parent = this.loadElement || $('.wrapper')
    } else {    
        parent = typeof parent === "string" ? $(parent) : parent
    }

    this.loading = false
    this.loadElement = undefined

    parent.children(".loader-overlay").remove()

    timer.remove("loader" + parent.attr("loader-id"))

    parent.css({
        "position": ""
    })
}

net.finishLoad = finishLoad

const _handleAuthProblem = async (data) => {
    if (typeof data === "string" && data.search("SIGN IN") !== -1 && window.location.pathname.search("login") === -1) {
        net.finishLoad()

        menu.create("Authentication", null, true)
        menu.setWindowClass("window-auto")
        menu.setHTML(await net.template("SessionTimeout"))

        timer.remove("sessionCheck")
        
        return
    }
}

const handleResponse = async (data, resolve, onSuccess) => {
    await _handleAuthProblem(data)
    const res = typeof data === "object" ? new Response(data) : data
    resolve(res)

    if (onSuccess) {
        onSuccess(res)
    }
}

// success and error callbacks are OBSOLETE and will be removed in future updates
export function request(method, url, success, error) {
    if (!success) {
        success = () => {}
    }
    if (!error) {
        error = () => {}
    }

    const cachedData = this.cachedRequestData[url]
    if (cachedData) {
        return new Promise((resolve) => {
            success(cachedData)
            resolve(cachedData)
        })
    }

    return new Promise(
        (resolve, reject) => {
            $.ajax({
                type: method.toUpperCase(),
                url: url,
                success: async (data) => {
                    if (url.search("templates") !== -1) {
                        this.cachedRequestData[url] = data
                    }
    
                    handleResponse(data, resolve, success)
                },
                error: (xhr) => {
                    const errorMsg = new Error("Request was failed with code " + xhr.code)
                    reject(errorMsg)
                    error(errorMsg)
                    net.finishLoad()
                }
            })
        }
    )
}

net.request = request

export function getCached(url) {
    return this.cachedRequestData[url]
}

net.getCached = getCached

export function json(method, url, data) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: method,
            url: url,
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify(data),
            success: async (data) => {
                handleResponse(data, resolve)
            },
            error: (xhr) => {
                reject(new Error(`Received response with code ${xhr.code} on endpoint: ${url}`))
            }
        })
    })
}

net.json = json

export function formData(method, url, formData) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: method,
            url: url,
            data: formData,
            processData: false,
            contentType: false,
            success: async (data) => {
                handleResponse(data, resolve)
            },
            error: (xhr) => {
                reject(new Error(`Received response with code ${xhr.code}`))
            }
        })
    })
}

net.formData = formData

export function template(path, params, success) {
    if (!success) {
        success = () => {}
    }

    const isArray = typeof path === "object"
    if (isArray) {
        path = path.reverse()
    }

    const loadTemplate = (resolve, reject, params, success, recursion) => {
        const templatePath = isArray ? path[path.length - 1] : path

        this.request("GET", "/templates?path=" + templatePath, async (html) => {
            for (const [key, value] of Object.entries(params || {})) {
                html = html.replaceAll("%" + key + "%", value)
            }

            if (path.length > 1 && isArray) {
                path.pop()
                recursion = recursion || []
                recursion.push(html)
                return loadTemplate(resolve, reject, params, success, recursion)
            } else {
                const result = recursion || html
                if (recursion) {
                    result.push(html)
                }
                
                success(result)
                return resolve(result)
            }
        }, (errorMsg) => {
            reject(errorMsg)
        })
    }

    return new Promise((resolve, reject) => {           
        loadTemplate(resolve, reject, params, success)
    })
}

net.template = template

export default net