import { getCommonParams } from "./modules/common-func.js"
import config from "../../lib/app-config.js"

const maxPaginators = config.pagination.maxPages
const urlParams = new URLSearchParams(window.location.search)

export default class Paginator {
    constructor(page = 1) {
        this._page = page
        this._pagination = 1
    }

    set pagination(value) {
        this._pagination = value
    }

    get pagination() {
        return this._pagination
    }

    set page(value) {
        this._page = value
    }

    get page() {
        return Number(this._page) || urlParams.get("page")
    }

    getPaginator() {
        return $(".paginator-row").last()
    }

    loadPages(pagination, callback) {
        const self = this,
            activePaginationClass = "paginator-active",
            currentPage = Math.min(this.page, pagination),
            paginatorElement = this.getPaginator();

        const pages = paginatorElement.children(".flx-row")
        pages.html("")

        let iterator = Math.max(currentPage - maxPaginators / 2, 1);

        for (let i = iterator; i < currentPage + maxPaginators / 2 + 1; i++) {    
            if (i > pagination) {
                break;
            }

            const activeClass = i === currentPage ? activePaginationClass : ""

            pages.append(/*html*/`
                <div class="paginator__item ${activeClass}">
                    <a class="paginator__link" href=""></a>
                </div>
            `)

            const paginatorLink = $(".paginator__link").last()
            const paginationItem = paginatorLink.parent(".paginator__item")

            if (callback) {
                paginatorLink.remove()

                paginationItem.css({
                    padding: "5px 10px"
                })

                paginationItem.on("click", function() {
                    const selPage = Number($(this).attr("page"))
                    callback(selPage)

                    self.page = selPage
                    self.loadPages(pagination, callback)
                })

                paginationItem.html(i)
                paginationItem.attr("page", i)
            } else {
                paginatorLink.attr("href", window.location.pathname + getCommonParams({
                    page: i
                }))
            
                paginationItem.attr("page", i)
                paginatorLink.html(i)
            };
        }

        const paginatorItems = $(".paginator__item")
        paginatorItems.on("click", function() {
            paginatorItems.removeClass(activePaginationClass)
            $(this).addClass(activePaginationClass)
        })
        
        if (this.page > pagination) {
            if (callback) {
                paginatorItems.last().trigger("click")
            } else {
                const href = window.location.pathname + getCommonParams({
                    page: pagination
                })
                window.location.replace(href)
            }
        }
    }

    init(pagination, callback) {
        const paginatorElement = this.getPaginator()

        if (typeof pagination === "undefined") {
            return;
        };

        pagination = Math.max(pagination, 1)

        const isRestrictedPage = window.location.pathname.search("dealer") === -1

        let options = ""
        config.pagination.segments.map(item => {
            const tag = isRestrictedPage ? "ctm-option" : "option"
            options += `<${tag} value="${item}">${item}</${tag}>`
        })

        paginatorElement.html(/*html*/`
            <div class="input-wrapper" style="position: absolute; left: 20px; top: 50%; transform: translateY(-50%); margin-bottom: 0">
                <span class="input__text">Display Items:</span>
                ${isRestrictedPage ? 
                    /*html*/`
                        <ctm-select no-update="true" simple-draw="true" id="max_items" name="max_items" class="input__combobox sz-sm">
                            ${options}
                        </ctm-select>
                    ` :
                    /*html*/`
                        <select class="inventory__header-input">
                            ${options}
                        </select>
                    `
                }
            </div>

            <div class="flx-row flx-center-abs gap-md"></div>
        `)

        this.loadPages(pagination, callback)
    }
}

jQuery(() => {
    if (typeof storage === "undefined" && typeof pagination !== "undefined")  {
        const pug = new Paginator()
        pug.page = Number(urlParams.get("page")) || 1
        pug.init(pagination) // can be defined in EJS template
    }
})