import component from "../modules/components.js"
import net from "../modules/network.js"
import notify from "../modules/notify.js"
import menu from "../modules/menu.js"

import 'webpack-jquery-ui/sortable'
import selection from "../modules/selection.js"

const Contracts = component.create({
    renderContractList: function() {
        let contractsList = ""

        this.contracts.sort((a, b) => a.type.localeCompare(b.type))

        let filtered = this.contracts
        if (this.searchValue && this.searchValue !== "") {
            filtered = this.contracts.filter(item => item.name.toLowerCase().search(this.searchValue) !== -1)
        }

        filtered.map(item => {
            const category = item.category || "car"
            if (category != this.dealType) return
    
            contractsList += /*html*/`
                <div class="contract-item" category="${category}">
                    <div class="checkbox sz-md">
                        <ctm-checkbox perm="true" name="${item.id}" ${this.vehicleCost >= 40000 && item.id === "cc_option" ? `disabled="true"` : ""} class="checkbox__element print-checkbox" />
                    </div>
                    <div class="contract__item sz-xxl">${item.name}</div>
                    <div class="contract__item sz-md">${item.type}</div>
                </div>
            `
        })

        return contractsList
    },

    renderPrintPacks: function() {
        let packsHTML = ""

        let filtered = this.printpacks
        if (this.searchValue && this.searchValue !== "") {
            filtered = this.printpacks.filter(item => item.packName.toLowerCase().search(this.searchValue) !== -1)
        }

        filtered.map(item => {
            const packId = item.packId
            packsHTML += /*html*/`
                <div class="contract-item" item-id="${packId}">
                    <div class="checkbox sz-md">
                        <ctm-checkbox perm="true" name="${packId}" class="checkbox__element print-checkbox" />
                    </div>
                    <div class="contract__item sz-xxl">${item.packName}</div>
                    <div class="contract__options">
                        <div class="contract__option option-rename" item-id="${packId}" hint-text="Edit Print Pack"></div>
                        <div class="contract__option option-remove" item-id="${packId}" hint-text="Remove Print Pack"></div>
                    </div>
                </div>
            `
        })

        return packsHTML
    },

    updateContent: async function() {
        const contracts = await net.request("GET", "/api/contracts")
        this.contracts = contracts.results
        this.contractsData = {}

        this.contracts.map(item => {
            this.contractsData[item.id] = item
        })

        const printpacks = await net.request("GET", "/api/printpacks")
        this.printpacks = printpacks.results
    },

    hookSortableList: function() {
        const self = this
        const contractsList = $(".contracts-list")
        if ($(window).outerWidth() > config.shared.mobileWidthBreakpoint) {
            contractsList.sortable({
                revert: 0,
                delay: 100,
                tolerance: "pointer",
            })
        }

        $(".option-remove:not([contract])").on("click", async function() {
            const packId = $(this).attr("item-id")

            $(".contract-item[item-id=" + packId + "]").remove()

            net.load()
            const res = await net.request("DELETE", "/api/printpacks/" + packId)
            await self.updateContent()
            net.finishLoad()
            res.receive(() => {
                notify.success("You have successfully removed this print pack!")
            }, {
                405: "Print Pack is already removed!"
            })
        })

        $(".option-rename").on("click", async function() {
            let contractsListHTML = ""

            const packId = $(this).attr("item-id")
            const pack = self.printpacks.find(item => item.packId == packId)
            pack.contracts.map(item => {
                const id = item.contractId
                const data = self.contractsData[id]
                if (!data) return
                contractsListHTML += /*html*/`
                    <div class="contract-item" contract="${id}">
                        <div class="contract__item sz-xxl">${data.name}</div>
                        <div class="contract__item sz-md">${data.type}</div>
                        <div class="contract__options">
                            <div class="contract__option option-remove" contract="${id}" hint-text="Remove Contract"></div>
                        </div>
                    </div>
                `
            })

            menu.create("Edit Print Pack #" + packId, true, false, () => {
                $(".top__item[category=PrintPacks]").trigger("click")
                return true
            })
            menu.setWindowClass("window-auto", true)
            menu.setHTML(/*html*/`
                <div class="contracts__wrapper">        
                    <div class="contracts">
                        <input class="chart__title" value="${pack.packName}" id="pack-name" style="margin-bottom: 10px; font-weight: 700;"> 

                        <div class="contracts__header">
                            <div class="contract__item sz-xxl">Name</div>
                            <div class="contract__item sz-md">Category</div>
                            <div class="sz-md"></div>
                        </div>
                        <div class="contracts-list" id="printpacks-viewer">
                            ${contractsListHTML}
                        </div>

                        <div class="contracts-footer">  
                            <div class="btn btn-flex btn-add" perm="true" id="save-printpack">
                                <div class="sidebar__item-img img-default sidebar-save"></div>
                                <span class="btn-text">Save</span>
                            </div>
                        </div>
                    </div>
                </div>
            `, true)

            self.hookSortableList()

            let packName = pack.packName

            $(".option-remove[contract]").on("click", function() {
                $(".contract-item[contract=" + $(this).attr("contract") + "]").remove()
            })

            $("#pack-name").on("blur", function() {
                packName = $(this).val()
            })

            $("#save-printpack").on("click", async () => {
                const contracts = []
                let index = 0
                $("#printpacks-viewer").children(".contract-item").each(function() {
                    const id = $(this).attr("contract")
                    contracts.push({
                        contractId: id,
                        contractOrder: index
                    })
                    index++
                })

                net.load()
                const res = await net.json("PUT", "/api/printpacks/" + packId, {
                    payload: {
                        packName: packName,
                        contracts: contracts
                    }
                })
                await self.updateContent()
                res.receive(() => {
                    notify.success("You have successfully saved Print Pack!")
                })
                net.finishLoad()
            })
        })
    },
    
    preRender: async function({ dealType, vehicleCost }) {
        this.dealType = dealType
        this.vehicleCost = vehicleCost
        this.elementsChecked = {}

        await this.updateContent()
    
        return /*html*/`
            <div class="contracts__wrapper">
                <div class="main-section__top">
                    <div class="top__item" window="true" category="Contracts">Contracts</div>
                    <div class="top__item" window="true" category="PrintPacks">Print Packs</div>
                </div>

                <div class="contracts" id="contracts-viewer"></div>
            </div>
        `
    },

    postRender: function({ dealId, ctx }) {
        const self = this
        const wrapper = $(".wrapper")
        const viewerObj = $("#contracts-viewer")
        const navButtons = $(".top__item[window]")
        const activeClass = "item-selected"

        const searchInputHTML = /*html*/`
            <div class="contracts__search">
                <div class="input sz-fill" style="margin-bottom: 0;">
                    <input placeholder="Search..." perm="true" value="" type="text" id="contracts-search" class="input__entry-default sz-fill" style="padding-left: 10px;">
                </div>
            </div>
        `

        const collectSignedContracts = (clear) => {
            const payload = []
            $(".print-checkbox").each(function() {
                const name = $(this).attr("name")
                if (!$(this).prop("checked")) {
                    return
                }

                if (clear) {
                    ctx.storage[name] = false
                }

                if ($(this).attr("block-docusign") && docusign) {
                    return
                }

                const packId = parseInt(name)
                if (isNaN(packId)) {
                    payload.push(name)
                } else {
                    const printPack = self.printpacks.find(item => item.packId === packId)

                    const contracts = printPack.contracts
                    contracts.sort((a, b) => a.contractOrder > b.contractOrder)

                    contracts.map(item => {
                        payload.push(item.contractId)
                    })
                }
            })

            if (clear) {
                ctx.writeValues()
            }

            return payload
        }

        const printFunc = async (callback, docusign) => {
            const payload = collectSignedContracts()

            if (payload.length === 0) {
                notify.error("You have to select at least one Contract!")
                return
            }

            net.load()

            const isLenderCategoty = ctx.category === "category_lender" ? "?lender=true" : ""
            const res = await net.json("POST", `/deals/${dealId}/contracts` + isLenderCategoty, {
                payload: payload, docusign: docusign
            })
            
            res.receive(() => {
                callback(res)
            }, {
                502: res.error || "An error has been dispatched during contract generation.<br>Please try again later"
            })

            net.finishLoad()
        }

        const hookCloseButton = () => {
            $(".hdr-alter").on("click", function() {
                const contractPanel = $(".contract-panel")
                contractPanel.css("transition", "transform 0.25s ease")
                contractPanel.css("transform", "translate(0%, 20%)")

                $(".contract-popup").animate({opacity: 0}, 250, function() {
                    $(this).remove()
                })
            })
        }

        const sendToDocusign = (type) => {
            printFunc(async (res) => {
                const link = res.payload
                const html = await net.template("deal/DocusignForm", {
                    deal_id: dealId,
                    link: link,
                })
                await component.appendHTML(wrapper, html)

                let file;
                const fileInput = $("#deal-file"),
                    btn = $("#btn-send")

                $(".contract-panel").on("paste", function (event) {
                    fileInput[0].files = event.originalEvent.clipboardData.files
                    fileInput.trigger("change")
                })

                fileInput.on("change", function() {
                    $(".file-hint").html(this.files[0].name)
                    file = this.files[0]
                })
                
                $("#dc-confirm").on("change", function() {
                    if (btn.attr("disabled")) {
                        btn.removeAttr("disabled")
                    } else {
                        btn.attr("disabled", "true")
                    }
                })

                btn.on("click", async function() {
                    if ($(this).attr("disabled")) {
                        return
                    }

                    net.load()

                    const viewerCloseBtn = $(".viewer-close")

                    if (file) {
                        const formData = new FormData()
                        formData.append("file", file)

                        const res = await net.formData("PUT", `/api/contracts/${dealId}?docusign=${type}`, formData)
                        res.receive(() => {
                            notify.success("Documents are now being generated by DocuSign<br>You will receive email with futher instructions soon!", 10)
                        }, {
                            406: "The file was not received by server! Please try again!",
                            407: "File size is larger than 8 MB!",
                            408: "Files with this extension cannot be sent!"
                        })

                        net.finishLoad()
                        viewerCloseBtn.trigger("click")
                    } else {
                        await net.request("PUT", "/api/contracts/" + dealId + "?file_id=" + link + "&docusign=" + type)
                        notify.success("Documents are now being generated by DocuSign<br>You will receive email with futher instructions soon!", 10)
                        net.finishLoad()
                        viewerCloseBtn.trigger("click")
                    }
                })

                hookCloseButton()
            }, type)
        }

        navButtons.on("click", function() {
            self.searchValue = ""

            const callback = {
                "Contracts": function() {
                    const contractsHTML = self.renderContractList()

                    return /*html*/`
                        ${searchInputHTML}
                        <div class="contracts__header">
                            <div class="sz-md"></div>
                            <div class="contract__item sz-xxl">Name</div>
                            <div class="contract__item sz-md">Category</div>
                        </div>
                        <div class="contracts-list">
                            ${contractsHTML}
                        </div>

                        <div class="contracts-footer">
                            <div class="btn btn-flex btn-nobackground" perm="true" id="submit-pack" hint-text="Create Print Packs to use them later" element-offset="10">
                                <div class="sidebar__item-img img-default sidebar-docusign"></div>
                                <span class="btn-text">Create Print Pack</span>
                            </div>

                            <div class="btn btn-flex" id="submit-print" perm="true" hint-text="Generate contracts to preview / save them" element-offset="10">
                                <div class="sidebar__item-img img-default sidebar-print"></div>
                                <span class="btn-text">Print</span>
                            </div>
                        </div>
                    `
                },

                "PrintPacks": function() {
                    const packsHTML = self.renderPrintPacks()

                    return /*html*/`
                        ${searchInputHTML}
                        <div class="contracts__header">
                            <div class="sz-md"></div>
                            <div class="contract__item sz-xxl">Name</div>
                            <div class="contract__item sz-md">Options</div>
                        </div>
                        <div class="contracts-list">
                            ${packsHTML}
                        </div>

                        <div class="contracts-footer">
                            <div class="btn btn-flex" id="submit-print" perm="true" hint-text="Generate contracts to preview / save them" element-offset="10">
                                <div class="sidebar__item-img img-default sidebar-print"></div>
                                <span class="btn-text">Print</span>
                            </div>
                        </div>
                    `
                }
            }

            navButtons.removeClass(activeClass)
            $(this).addClass(activeClass)

            const category = $(this).attr("category")
            const html = callback[category]()
            viewerObj.html(html)

            ctx.customElements()
            ctx.hookInputs()
            ctx.writeValues()

            self.hookSortableList()

            $("#contracts-search").on("input", function() {
                const value = $(this).val()
    
                self.searchValue = value.toLowerCase().trim()
                const html = category === "Contracts" ? self.renderContractList() : self.renderPrintPacks()

                $(".contracts-list").html(html)

                ctx.customElements()
                ctx.hookInputs()
                ctx.writeValues()
            })

            $("#submit-print").off().on("click", (e) => {
                selection.create($(".popup-window"), true, [
                    {
                        name: "Local",
                        icon: "sidebar-print",
                        callback: () => {
                            printFunc(async (res) => {
                                const link = res.payload
                                const html = await net.template("deal/ContractPrinted", {
                                    deal_id: dealId,
                                    link: link
                                })
                
                                await component.appendHTML(wrapper, html)
                
                                hookCloseButton()
                            }, false)
                        }
                    },
                    {
                        name: "DocuSign",
                        icon: "sidebar-docusign",
                        callback: () => {
                            sendToDocusign("default")
                        }
                    },
                    {
                        name: "DocuSign (In-Person)",
                        icon: "sidebar-docusign",
                        callback: () => {
                            sendToDocusign("inperson")
                        }
                    }
                ])
    
                e.stopPropagation()
            })
    
            $("#submit-pack").off().on("click", async () => {
                const payload = collectSignedContracts()
    
                if (payload.length < 2) {
                    return notify.error("You have to select at least two contracts to create print pack!", 10)
                }
    
                menu.create("Create Print Pack", true)
                menu.setWindowClass("window-auto", true)
                menu.setHTML(await net.template("deal/ContractPrintPack"), true)
    
                let packName = ""
                $("#printpack_name").on("input", function() {
                    packName = $(this).val()
                })
                
                $("#printpack-btn").on("click", async () => {
                    packName = packName.trim().replace(/[^a-zA-Z0-9]/g, "")
    
                    if (packName === "") {
                        return notify.error("You cannot create Pack with empty name!")
                    }
    
                    net.load()
                    const res = await net.json("POST", "/api/printpacks?packName=" + packName, {
                        payload: payload
                    })
                    await self.updateContent()

                    net.finishLoad()
    
                    res.receive(() => {
                        collectSignedContracts(true)
                        menu.close("-2")
                        notify.success("You have successfully created new Print Pack!")
                    })
                })
            })
        })

        navButtons.first().trigger("click")
    }
})

export default Contracts