const timer = {
    stored: [],

    create: function(tmrId, duration, reps, callback) {
        if (this.exists(tmrId)) {
            this.remove(tmrId)
        }

        const durationMs = duration * 1000

        let tmrObj;
        let repsLeft = reps

        const isInterval = typeof reps === "number"
        if (isInterval) {
            tmrObj = setInterval(() => {
                callback()

                if (reps !== 0) {
                    repsLeft--

                    if (repsLeft <= 0) {
                        clearInterval(tmrObj)
                    }
                }
            }, durationMs)
        } else {
            tmrObj = setTimeout(callback, durationMs)
        }

        if (tmrId) {
            this.stored.push({
                tmrId: tmrId,
                tmrObj: tmrObj,
                isInterval: isInterval
            })
        }
    },

    exists: function(tmrId) {
        return this.stored.some(item => item.tmrId === tmrId)
    },

    remove: function(tmrId) {
        if (!this.exists(tmrId)) {
            return
        }

        const filtered = this.stored.filter(item => {
            if (item.tmrId === tmrId) {
                const clearFunc = item.isInterval ? clearInterval : clearTimeout
                clearFunc(item.tmrObj)

                return false
            }

            return true
        })
        
        this.stored = filtered
    },

    simple: function(duration, callback) {
        this.create(null, duration, 1, callback)
    }

}

export default timer;