import Paginator from "../paginator.js"

import config from "../../../lib/app-config.js"
import notify from "./notify.js"

import { round } from "./common-func.js"

const dealFields = config.deal.fields

export function calcPayment(returnType, value, mod = "") {
    const PeriodType = this.getStoredValue("term_count", true)
    const periods = this.getAnnual()

    let AmountFinanced = Number(this.getStoredValue("amount_financed" + mod))
    
    let PeriodAmount = 1
    if (returnType !== "term" && PeriodType !== "one_pay") {
        PeriodAmount = Number(this.getStoredValue("term"))
    }

    let APR = Number(this.getStoredValue("interest_rate", true)) / 100 / periods
    let PeriodPayment = Number(this.getStoredValue("monthly_payment", true))

    const DealDate = new Date(this.storage["deal_date"])
    const FirstPaymentDate = new Date(this.getStoredValue("first_payment_date"))

    const daysPassed = new Date(DealDate.getFullYear(), DealDate.getMonth() + 1, 0).getDate()
    const NextMonthDate = new Date(DealDate.getTime() + 3600 * 24 * 1000 * daysPassed)

    const firstPaymentDays = Number(this.getStoredValue("first_payment", true))

    let i = NextMonthDate.getMonth()
    let diff = 0
    switch (PeriodType) {
        case "months": {
            const startMonth = NextMonthDate.getMonth(),
                startYear = NextMonthDate.getFullYear();

            let month = startMonth
            let year = startYear
            let surplus = 0

            diff = -NextMonthDate.getDate()

            while (true) {
                const daysInMonth = new Date(year, month + 1, 0).getDate()
                const monthDate = new Date(year, month + 1, 1)

                if (surplus > 0) {
                    diff += surplus
                }

                surplus = 31 - daysInMonth

                if (monthDate.getTime() <= FirstPaymentDate.getTime()) {
                    diff += 30
                } else {
                    diff += Math.min(FirstPaymentDate.getDate(), 30)

                    break
                }

                if (month > 11) {
                    month = 0
                    year++
                } else {
                    month++
                }
            }

            diff = Math.max(diff, 0)

            break
        }
        case "semi_months": {
            diff = Math.max((firstPaymentDays - 15), 0)
            break
        }
        case "bi_weeks": {
            diff = Math.max((firstPaymentDays - 14), 0)
            break
        }
        case "weeks": {
            diff = Math.max((firstPaymentDays - 7), 0)
            break
        }
        case "days": {
            diff = Math.max((firstPaymentDays - 1), 0)
            break
        }
    }

    const dailyInterest = (Number(this.getStoredValue("interest_rate", true)) / 100 / 360)

    const perDiem = AmountFinanced * dailyInterest
    AmountFinanced += perDiem * diff

    // * Has no effect on calculations, but has to STAY!!!
    const Factor = 1

    const fixedPaymentCheckbox = $("#fixed_payment")
    fixedPaymentCheckbox.removeAttr("disabled")
    
    let result = (AmountFinanced * Factor * APR * Math.pow(1 + APR, PeriodAmount)) / (Math.pow(1 + APR, PeriodAmount) - 1)

    if (APR === 0) {
        result = AmountFinanced / PeriodAmount
    }

    if (!returnType) {
        return round(result, 2)
    }

    switch (returnType) {
        case "term": {
            result = round((Math.log(Factor) - Math.log((PeriodPayment - APR * AmountFinanced) / PeriodPayment)) / Math.log(1 + APR))
            break
        }
        case "rollback": {
            result = (value * (Math.pow(1 + APR, PeriodAmount) - 1)) / (APR * Math.pow(1 + APR, PeriodAmount))
            result -= (result * dailyInterest) * diff

            break
        }
        case "apr": {
            const apy = Number(this.getStoredValue("apy"), true) / 100 / periods
            
            let modifiedLoanAmount = AmountFinanced,
                targetLoanAmount = AmountFinanced,
                sumApy = 0;
        
            for (i = 1; i <= PeriodAmount; i++) {
                sumApy += apy
                targetLoanAmount += modifiedLoanAmount * apy
                if (i % periods === 0) {
                    modifiedLoanAmount = targetLoanAmount
                }
            }
    
            let controlVal = AmountFinanced
            let increment = 0
            while (true) {
                increment += 0.001
                APR = increment / 100 / periods
                controlVal = (AmountFinanced * Factor * APR * Math.pow(1 + APR, PeriodAmount)) / (Math.pow(1 + APR, PeriodAmount) - 1) * PeriodAmount
    
                if (increment > 200) {
                    break
                }

                if (controlVal >= targetLoanAmount) {
                    break
                }
            }
    
            result = APR * 100 * periods
            break
        }
        case "apy": {
            let controlVal = result * PeriodAmount,
                targetLoanAmount = AmountFinanced,
                increment = 0;

            while (true) {
                let currentLoanAmount = AmountFinanced,
                    modifiedLoanAmount = AmountFinanced;
    
                increment += 0.0001
                const apy = increment / 100 / periods

                for (i = 1; i <= PeriodAmount; i++) {
                    currentLoanAmount += modifiedLoanAmount * apy
                    if (i % periods === 0) {
                        modifiedLoanAmount = currentLoanAmount
                    }
                }

                if (increment > 200) {
                    break
                }

                targetLoanAmount = currentLoanAmount

                if (controlVal <= targetLoanAmount) {
                    break
                }

            }

            increment -= 0.0001

            result = increment
            break
        }
    }

    return result
}

const customListGroups = {
    insurance: "Insurances",
    gap: "Gaps",
    vsi_insurance: "VSI Insurances",
    vendor: "Vendors",
    lienholders: "Lienholders"
}

export function hookSearchButtons() {
    const struct = this
    const dealType = this.storage.deal_type

    const callback = {
        vehicle_trade_1: () => {
            this.editVehicle("Select Vehicle for Trade In 1", "vehicle_trade_1")
        },
        vehicle_trade_2: () => {
            this.editVehicle("Select Vehicle for Trade In 2", "vehicle_trade_2")
        },
        vehicle_trade_1_remove: () => {
            this.removeVehicle("vehicle_trade_1")
        },
        vehicle_trade_2_remove: () => {
            this.removeVehicle("vehicle_trade_2")
        },
    }

    const openList = async (inputId, listType, template, id, value, useID) => {
        const res = await this.request("GET", "/selection?type=" + listType)
        const payload = res.payload

        this.showWindow(customListGroups[listType])
        this.setWindowPanelClass("flx-clm")

        for (let i = 0; i < payload.length; i++) {
            const item = payload[i]
            const html = await this.template(template, {
                id: item[id],
                name: item[value]
            })
            $(".panel__list").append(html)
        }

        $(".selection-remove").on("click", async function(event) {
            event.stopPropagation()

            const parent = $(this).parent(".selection__item")
            parent.remove()

            struct.load()
            await struct.request("DELETE", "/selection?type=" + listType + "&id=" + parent.attr("item-id"))
            struct.finishLoad()
        })

        $(".selection__item").on("click", function(event) {
            const value = $(this).attr("value")

            if (useID) {
                struct.storage[inputId] = Number($(this).attr("item-id"))
                inputId = inputId + "_value"
            }

            const element = $(`[name=${inputId}]`)

            if (struct.storage[inputId] !== value) {
                struct.onUpdate("#unsaved-deal", element)
            }

            const accessory = element.attr("accessory-id")
            if (typeof accessory !== "undefined") {                        
                const refID = inputId.replace(/_[0-9]/g, '')
                struct.storage["accessories"][accessory][refID] = value
            }

            struct.storage[inputId] = value

            struct.writeValue(element, value)
            element.val(value)
            struct.closeWindow()
                
            event.stopPropagation()
        })

        this.finishLoad()
    }

    $("[list-type]").off().on("click", function(event) {
        const inputId = $(this).attr("for") + struct.getSuffix()

        const input = $("#" + inputId)

        if (input.attr("disabled")) {
            notify.error("You can't edit this field right now!")
            return
        }

        const listType = $(this).attr("list-type")
        if (!listType) {
            return
        }

        if ($(this).attr("disabled")) {
            return
        }

        if (listType === "lender") {
            if (dealType === "car" && !struct.storage["vehicle_id"]) {
                struct.error("You have to assign Vehicle first!", 5)
                return
            }

            if (!struct.storage["customer_id"]) {
                struct.error("You have to assign Customer first!", 5)
                return
            }
        }

        struct.load()

        if (callback[listType]) {
            callback[listType]()
        } else {
            openList(inputId, listType, "deal/SelectionItem", "selection_id", "selection_value")
        }

        event.stopPropagation()
    })
}

// Shortcut function that initializes pug on popup window with certain callback "func"
export function initPug(res, type, func) {
    const pagination = res.pagination
    const Pag = new Paginator()
    Pag.page =  this.params.page || 1

    Pag.init(pagination, async (page) => {
        $("#query-list").html("")
        this.setCustomParam("page", page)
        this.load(".list__wrapper")
        const res = await this.request("GET", window.location.pathname + type + this.getCustomParams())
        this.finishLoad()
        func(res.payload)
    })
}

export const dealHeaderPanelCallbacks = {
    vehicle_id: async function() {
        return await this.editVehicle("Select Vehicle for Deal", "vehicle_id", false)
    },
    change_vehicle: async function() {
        return await this.editVehicle("Select Vehicle for Deal", "vehicle_id", true)
    },
    customer_id: async function() {
        return await this.editClient()
    },
    change_client: async function() {
        return await this.changeClient()
    },
    cobuyer_id: async function() {
        return await this.editClient(true)
    },
    change_cobuyer: async function() {
        return await this.changeClient(true)
    },
    remove_cobuyer: async function() {
        return await this.removeCoBuyer()
    },
    StatusDate: 60
}

const getFieldFormatting = (id) => {
    const char = dealFields[id]?.format

    switch (char) {
        case null: {
            return " input-number"
        }
        case "%": {
            return " input-percent"
        }
    }

    return ""
}

const InputWrapper = (name, id, type, disabled, popupType, bold) => {
    if (popupType === true) {
        popupType = id
    }

    const popupDiv = popupType ? `<div class="input__dots" popup-type="${popupType}"></div>` : ""

    return `
        <div class="input-wrapper">
            <span id=${id + "_label"} class="input__text${bold ? " text-bold" : ""}">${name}</span>
            <input placeholder="${name}" ${disabled ? "disabled" : ""} value="" type="${type}" name="${id}" id="${id}" class="input__entry${getFieldFormatting(id)}">
            ${popupDiv}
        </div>
    `
}

const InputPopup = (name, id, type, disabled, popupType) => {
    if (popupType === true) {
        popupType = id
    }

    const popupDiv = popupType ? `<div class="input__dots" popup-type="${popupType}"></div>` : ""

    return /*html*/`
        <div class="input">
            <span id=${id + "_label"} class="input__text --column">${name}</span>
            <div class="input-wrap">
                <input placeholder="${name}" ${disabled ? "disabled" : ""} value="" type="${type}" name="${id}" id="${id}" class="input__entry sz-xl${getFieldFormatting(id)}">
                ${popupDiv}
            </div>
        </div>
    `
}

const InputCombo = (name, id) => {
    const labelId = id + "_label"
    const comboId = id + "_count"

    const termList =  {
        months: "months",
        semi_months: "semi-mths",
        bi_weeks: "bi-weeks",
        weeks: "weeks",
        days: "days",
        one_pay: "One Pay"
    }

    let options = ""
    for (const [key, value] of Object.entries(termList)) {
        options += `<ctm-option value="${key}">${value}</ctm-option>`
    }

    return /*html*/`
        <div class="input-wrapper resp-height-auto">
            <span ${labelId} class="input__text">${name}</span>
            <div class="input-section resp-flx-column">
                <input value="" type="number" name="${id}" id="${id}" class="input__entry input-number">
                <ctm-select name="${comboId}" id="${comboId}" class="input__combobox">
                    ${options}
                </ctm-select>
            </div>
        </div>
    `
}

const ComboSubscription = (name, id) => {
    const labelId = id + "_label"
    const comboId = id + "_count"

    const termList =  {
        months: "monthly",
        bi_weeks: "bi-weekly",
        weeks: "weekly",
        days: "daily",
    }

    let options = ""
    for (const [key, value] of Object.entries(termList)) {
        options += `<ctm-option value="${key}">${value}</ctm-option>`
    }

    return /*html*/`
        <div class="input-wrapper responsive-adjust">
            <span ${labelId} class="input__text">${name}</span>
            <div class="input --input-divided">
                <ctm-select name="${comboId}" id="${comboId}" class="input__combobox" style="min-width: 100px;">
                    ${options}
                </ctm-select>
            </div>
        </div>
    `
}

const InputFirstPayment = (name, id) => {
    const dateId = id + "_date" + "%suffix%"

    return /*html*/`
        <div class="input-wrapper" id="${id + "_element"}">
            <span class="input__text">${name}</span>
            <input value="" type="number" name="${id}" id="${id}" min="30" class="input__entry input-number">
            <div class="input__functions">
                <div for="${id}" class="input__functions-arrows">
                    <div class="input__functions-arrow"></div>
                    <div class="input__functions-arrow --arrow-down"></div>
                </div>
                <div class="calendar__image" calendar="${dateId}"></div>
            </div>

            <div class="input__calendar input__calendar--options" style="position: absolute; opacity: 0; z-index: -1; right: 0">
                <input disabled type="text" autocomplete="off" name="${dateId}" id="${dateId}" limit="30" style="opacity: 0;" view-lock="true" class="input__calendar-element">
            </div>
        </div>
    `
}

const InputCalendar = (name, id) => {
    return /*html*/`
        <div class="input-wrapper">
            <span class="input__text">${name}</span>
            <div class="input__calendar input__calendar--options">
                <input type="text" autocomplete="dealcenter" name="${id}" id="${id}" limit="min" class="input__calendar">
                <div class="calendar__image" calendar="${id}"></div>
            </div>
        </div>
    `
}

export const layout = {
    loader: function (dealType = "car", category = "shared", suffix = "") {
        const typeLayout = this[dealType]
        if (!typeLayout) {
            throw new Error("No layout is available for Deal Type " + dealType)
        }

        const segmentLayout = typeLayout[category]

        if (typeLayout.disableStatistics) {
            $(".summary-wrapper").remove()
        }

        for (const [wrapper, htmlElements] of Object.entries(segmentLayout || {})) {
            const id = wrapper + suffix
            const parent = $("#" + id)

            const length = htmlElements.length

            if (length === 0) {
                $(`.horizontal-line[for=${id}]`).remove()
            }

            for (let i = 0; i < length; i++) {
                const html = htmlElements[i].replaceAll("%suffix%", "")
                parent.append(html)
            }
        }
    },

    car: {
        shared: {
            top: [
                InputWrapper("Price", "price", "number", false, true),
                InputWrapper("Fees", "fees_view", "number", true, "fees"),
                InputWrapper("Ins / GAP / VSI", "insurance", "number", true, true),
                InputWrapper("Accessories", "accessories_total", "number", true, true),
                InputWrapper("VSC / Products", "vcs_products", "number", true, true),
                InputWrapper("Total Tax", "total_tax", "number", true, true),
                InputWrapper("Down Payment", "down_payment", "number", false, true),
                InputWrapper("Trade In", "trade_in", "number", true, true),
            ],
            bottom: [
                InputWrapper("Amount Financed", "amount_financed", "number", true, true, true),
                InputCombo("Term", "term"),
                InputWrapper("Interest Rate", "interest_rate", "number", false, true, false),
                InputWrapper("Monthly Payment", "monthly_payment", "number", true, true, false),
                InputFirstPayment("First Payment", "first_payment"),
                InputWrapper("PTI", "pti", "number", true),
                InputWrapper("LTV", "ltv", "number", true)
            ],
            middle: [
                InputPopup("Discount (Prepaid Interest)", "discount", "number", false, true),
                InputPopup("Net Check", "net_check", "number", true)
            ]
        },
        category_deal: {

        },
        category_lender: {
            bottom: [
                InputWrapper("PTI (DealCenter)", "pti_ocrolus", "number", true),
                InputWrapper("LTV (Lender)", "ltv_lender", "number", true)
            ]
        }
    },
    personal: {
        shared: {
            top: [],
            bottom: [
                InputWrapper("Amount Financed", "amount_financed", "number", false, true, true),
                InputCombo("Term", "term"),
                InputWrapper("Interest Rate", "interest_rate", "number", false, true, false),
                InputWrapper("Monthly Payment", "monthly_payment", "number", true, true, false),
                InputFirstPayment("First Payment", "first_payment"),
                InputWrapper("PTI", "pti", "number", true)
            ],
            middle: [
                InputPopup("Discount (Prepaid Interest)", "discount", "number", false, true),
                InputPopup("Net Check", "net_check", "number", true)
            ]
        },

        category_deal: {

        },
        
        category_lender: {
            bottom: [
                InputWrapper("PTI (DealCenter)", "pti_ocrolus", "number", true)
            ]
        }
    },
    business: {
        shared: {
            top: [],
            bottom: [
                InputWrapper("Amount Financed", "amount_financed", "number", false, true, true),
                InputCombo("Term", "term"),
                InputWrapper("Interest Rate", "interest_rate", "number", false, true, false),
                InputWrapper("APY", "apy", "number"),
                InputWrapper("IRR", "irr", "number", true),
                InputWrapper("Monthly Payment", "monthly_payment", "number", true, true, false),
                InputFirstPayment("First Payment", "first_payment"),
                InputWrapper("PTI", "pti", "number", true)
            ],
            middle: [
                InputPopup("Discount (Prepaid Interest)", "discount", "number", false, true),
                InputPopup("Closing Fee", "closing_fee", "number", false, true),
                InputPopup("Net Check", "net_check", "number", true)
            ]
        },
        
        category_deal: {

        },

        category_lender: {
            bottom: [
                InputWrapper("PTI (DealCenter)", "pti_ocrolus", "number", true)
            ]
        }
    },

    subscription: {
        disableStatistics: true,

        shared: {
            top: [],
            bottom: [
                ComboSubscription("Payment Frequency", "term"),
                InputWrapper("Monthly Payment", "monthly_payment", "number"),
                InputCalendar("Start Date", "deal_date")
            ],
            middle: []
        },
    }
}