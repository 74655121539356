'use strict';

import cf, { getCommonParams } from "./modules/common-func.js";

import Contracts from "./components/Contracts.js"
import Stipulations from "./components/Stipulations.js"
import Lenders from "./components/Lenders.js"

import timer from "./modules/timer.js";
import notify from "./modules/notify.js";
import net from "./modules/network.js";
import menu from "./modules/menu.js";
import selection from "./modules/selection.js";
import hook from "./modules/hook.js";
import component from "./modules/components.js";

import config from "../../lib/app-config.js"
import Paginator from "./paginator.js"

window.MutationObserver = window.MutationObserver || window.WebKitMutationObserver

Number.prototype.clamp = function(min, max) {
    return Math.min(Math.max(this, min), max);
}

class Structure {
    constructor(setup) {
        window.storedCustomElements = {}

        this._attachedLists = {}
        this._compute = {}
        this._views = {}
        this._storage = {}
        this._collateralData = {}
        this._fStorage = {}
        this._viewOffer = false
        this._mousePos = {x: -1, y: -1}
        this._items = 0
        this._datepickers = []
        this._lastDatepicker = null
        this._popupElement = null
        this._lastButton = null
        this._category = null
        this._updateID = null
        this._windowCloseTimer = null
        this.params = {}
        this._loadElement = null
        this._loading = false
        this._cached = false
        this._clickStartedElement = null
        this._clickEndedElement = null
        this._createdHint = false
        
        this.sidebarInitialized = false

        this.updatedValues = {}
        this._cachedPopups = {}
        this._cachedRequestData = {}

        this.sortableClassConfig = "sort-column asc desc"

        this.months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

        this._dealStatus = config.deal.statuses

        this._paymentValues = config.deal.paymentFrequency
        
        this.permissions = config.account.permissions

        this._stateTranslate = config.shared.stateCodesMap
        this._stateTranslateSwapped = config.shared.stateCodesMapSwapped

        this.sidebarHiddenClass = "sidebar-hidden"

        for (const [key, value] of Object.entries(setup || {})) {
            this[key] = value
        }
        
        this.onDocumentReady = async function() {
            await this._onDocumentReady()

            const func = setup.onDocumentReady
            if (func) {
                func.call(this)
            }
        }

        jQuery(() => {
            if (this.modal) return
            this.initialize()
        })
    }

    set viewOffer(boolean) {
        this._viewOffer = boolean
    }

    get viewOffer() {
        return this._viewOffer
    }

    set createdHint(boolean) {
        this._createdHint = boolean
    }

    get createdHint() {
        return this._createdHint
    }

    set attachedLists(value) {
        this._attachedLists = value
    }

    get attachedLists() {
        return this._attachedLists
    }

    set stateTranslate(value) {
        this._stateTranslate = value
    }

    get stateTranslate() {
        return this._stateTranslate
    }

    set stateTranslateSwapped(value) {
        this._stateTranslateSwapped = value
    }

    get stateTranslateSwapped() {
        return this._stateTranslateSwapped
    }

    set cachedPopups(value) {
        this._cachedPopups = value
    }

    get cachedPopups() {
        return this._cachedPopups
    }

    set cached(value) {
        this._cached = value
    }

    get cached() {
        return this._cached
    }

    set loading(value) {
        this._loading = value
    }

    get loading() {
        return this._loading
    }

    set loadElement(element) {
        this._loadElement = element
    }

    set windowCloseTimer(value) {
        this._windowCloseTimer = value
    }

    get windowCloseTimer() {
        return this._windowCloseTimer
    }

    get loadElement() {
        return this._loadElement
    }

    set category(value) {
        this._category = value
    }

    get category() {
        return this._category
    }

    get dealStatus() {
        return this._dealStatus
    }

    set compute(object) {
        this._compute = object
    }

    get compute() {
        return this._compute
    }

    set views(object) {
        this._views = object
    }

    get views() {
        return this._views
    }

    set lastButton(value) {
        this._lastButton = value
    }

    get lastButton() {
        return this._lastButton
    }

    set storage(object) {
        // obsolete for now
        if (this.dataDivision) {
            if (this.viewOffer) {
                this._fStorage = object
                return
            }
    
            this._fStorage = Object.create(object)
            this._storage = Object.create(object)
            
            return
        }

        this._storage = object
    }

    get storage() {
        if (this.viewOffer) {
            return this._fStorage
        }
        return this._storage
    }

    set collateralData(object) {
        this._collateralData = object
    }

    get collateralData() {
        return this._collateralData
    }

    set updated(value) {
        this._updated = value
    }

    get updated() {
        return this._updated
    }

    set items(value) {
        this._items = value
    }

    get items() {
        return this._items
    }

    get currentMousePos() {
        return this._mousePos
    }

    set currentMousePos(object) {
        this._mousePos = object
    }

    set datepickers(array) {
        this._datepickers = array
    }

    get datepickers() {
        return this._datepickers
    }

    set lastDatepicker(value) {
        this._lastDatepicker = value
    }

    get lastDatepicker() {
        return this._lastDatepicker
    }

    set popupElem(value) {
        this._popupElement = value
    }

    get popupElem() {
        return this._popupElement
    }

    set paymentValues(value) {
        this._paymentValues = value
    }

    get paymentValues() {
        return this._paymentValues
    }

    set clickStartedElement(value) {
        this._clickStartedElement = value
    }

    get clickStartedElement() {
        return this._clickStartedElement
    }

    set clickEndedElement(value) {
        this._clickEndedElement = value
    }

    get clickEndedElement() {
        return this._clickEndedElement
    }

    set updateID(value) {
        this._updateID = value
    }

    get updateID() {
        return this._updateID
    }

    set cachedRequestData(value) {
        this._cachedRequestData = value
    }

    get cachedRequestData() {
        return this._cachedRequestData
    }

    /**
     * Renders the sidebar based on the provided list of buttons.
     *
     * @return {void}
     */
    renderSidebar() {
        const sidebarList = $(".sidebar__list").last()
        const list = this.sidebarButtons

        const isHidden = $(".sidebar-section").last().hasClass(this.sidebarHiddenClass)

        if (list) {
            sidebarList.html("")
            for (let i = 0; i < list.length; i++) {
                const ctx = list[i]

                if (ctx.preRender) {
                    const modified = ctx.preRender.call(this)
                    for (const [key, value] of Object.entries(modified || {})) {
                        ctx[key] = value
                    }
                }
    
                if (ctx.hideModal && this.modal) continue
                if (ctx.hideNonModal && !this.modal) continue
                if (ctx.canShow && !ctx.canShow.call(this)) continue
                if ((this.storage.removed || this.storage.disabled) && !ctx.url) continue
    
                const url = ctx.url
                const tag = url ? "a" : "div"
    
                sidebarList.append(`
                    <${tag} class="sidebar__item">
                        <div class="sidebar__item-img ${ctx.icon}"></div>
                        <span class="sidebar__item-text">${ctx.text}</span>
                    </${tag}>
                `)
                
                const btn = $(".sidebar__item").last()
                    
                if (isHidden) {
                    btn.attr("hint-text", ctx.text)
                    btn.attr("hint-dir", "right")
                }

                if (ctx.hint) {
                    btn.attr("hint-text", ctx.hint)
                    btn.attr("perm-hint", "true")
                }
    
                if (url) {
                    btn.attr("href", this.getURLWithSavedParams(url))
                    continue
                }
    
                btn.on("click", async (e) => {
                    if (ctx.checkSave) {
                        await this.checkSave()
                    }
                    
                    ctx.callback.call(this, btn)
    
                    e.stopPropagation()
                })
            }
        }

        this.sidebarToggle()
    }

    getURLWithSavedParams(url) {
        const queryMemory = JSON.parse(localStorage.getItem("queryMemory")) || {}
        const split = window.location.pathname.split("/")
        split.pop()

        const path = split.join("/")
        const fullURL = url + getCommonParams(queryMemory[path] || {}) 

        return fullURL
    }

    sidebarToggle() {
        const self = this
        const sClass = this.sidebarHiddenClass

        const sidebarSection = $(".sidebar-section")
        const sidebarHideBtn = $(".sidebar__hide")

        timer.simple(0.1, () => {
            sidebarSection.addClass("sidebar-transition")
        })

        sidebarHideBtn.off().on("click", function(event, init) {
            let sidebar = $("#" + $(this).attr("for"))
            if (sidebar.length === 0) {
                sidebar = $("#sidebar-section")
            }

            if (init && sidebar.attr("initialized")) return
            
            sidebar.attr("initialized", "true")

            const isHidden = sidebar.hasClass(sClass)

            $(".sidebar__item-text").each(function() {
                const parent = $(this).parent(".sidebar__item")
                const text =  $(this).html()
                if (text === "") return

                const isPermanent = parent.attr("perm-hint")

                if (isHidden) {
                    if (!isPermanent) {
                        parent.removeAttr("hint-text", "")
                    }
                    parent.removeAttr("hint-dir")
                    parent.removeAttr("element-offset")
                } else {
                    if (!isPermanent) {
                        parent.attr("hint-text", text)
                    }
                    parent.attr("hint-dir", "right")
                    parent.attr("element-offset", "5")
                }
            })
        
            if (isHidden) {
                if (init) {
                    return
                }

                localStorage.setItem(sClass, '0')
                sidebar.removeClass(sClass)
            } else {
                localStorage.setItem(sClass, '1')
                sidebar.addClass(sClass)
            }

            self.updateHints()
        })

        if (localStorage.getItem(sClass) === '1') {
            sidebarHideBtn.trigger("click", [true])
        }
    }

    /**
     * Initializes mutation observers to track changes in the document and custom scrollbar.
     *
     * Observes the document for subtree and child list changes, and updates hints and custom elements accordingly.
     * Also observes the custom scrollbar for style changes and removes popup selections.
     *
     * @return {void}
     */
    mutationObservers() {
        new MutationObserver((e) => {
            if (this.modal) return
            // if (!e[0].removedNodes[0]) {
            //     if (!this.createdHint) {
            //         $(".hover-message").remove()
            //     }
                
            //     this.createdHint = false
            // }

            this.hookSectionCollapsible()
            this.updateHints()
        }).observe(document, {subtree: true, childList: true})

        cf.customScrollbarTracker()
    }

    async getWarningBanner(text, styleData) {
        const options = {
            text: text,
            icon: "",
            style: "",
            hintText: ""
        }

        for (const [key, value] of Object.entries(styleData || {})) {
            options[key] = value
        }

        return await this.template("collaterals/Warning", options)
    }

    async getDealsPresence() {
        if (!this.isCollateral) {
            return
        }

        const props = this.storage.customer_id ? {
            pk: "customer_id",
            route: "customers"
        } : {
            pk: "vehicle_id",
            route: "vehicles"
        }

        const res = await this.request("GET", `/${props.route}/${this.storage[props.pk]}/collaterals`)
        let payload = res.payload

        let isFormDisabled;
        if (payload.length > 0) {
            const html = await this.template("collaterals/List")
            $(".collateral-section").append(html)
        }
        payload = payload.reverse()
        let counter = 0
        payload.map(async (item) => {            
            if (item.deal_status !== "deal_status_init" && item.deal_status !== "deal_status_denied") {
                isFormDisabled = true
            }

            if (counter > 10) return

            let statuses = this.dealStatus[item.deal_type]
            if (!statuses) {
                statuses = this.dealStatus.base
            }

            const statusData = statuses[item.deal_status]

            const html = await this.template("collaterals/Item", {
                dealId: item.deal_id,
                status: typeof statusData === "object" ? statusData.value : statusData
            })

            $("#active-deals").append(html)

            this.updateStatusMark($(".collaterals__item-status").last(), item.deal_status)
            counter++
        })

        this.isFormDisabled = isFormDisabled

        if (isFormDisabled) {
            const html = await this.getWarningBanner("You are not allowed to change this form because it is participating in an active deal(s)")
            $(".collateral-section").prepend(html)
        }

        this.normalizeInputs()
    }

    async defineFormAccess(baseController, masterController) {
        const info = this.account
        const userId = info.main ? info.user_id : info.parent_id

        let isFormDisabled;
        if (info.user_type !== "type_root") {
            if (this.isFormDisabled && (!masterController || masterController(userId))) {
                isFormDisabled = true
            } else if (baseController) {
                isFormDisabled = baseController(userId)
                $(".collaterals__warning").remove()
            }
        } else {
            $(".collaterals__warning").remove()
        }

        if (info.permissions.indexOf("business") === -1 && info.user_type !== "type_root") {
            $("#business-info").remove()
        }

        if (isFormDisabled) {
            $("input[name], ctm-select:not([perm]), ctm-checkbox").attr("disabled", true)
            $(".btn:not([perm]), .reference-delete, #sidebar-section").remove()
            this.setupDatepickers(true)
        } else {
            this.setupDatepickers()
        }

        return isFormDisabled
    }

    hookSectionCollapsible() {
        const context = JSON.parse(localStorage.getItem("collapsedItems")) || {}
        const activeClass = "section__header-collapsed"
        const animationTime = 0.25
        const collapse = (elem, isInstant) => {
            timer.remove("restoreHeight")
            elem.prop("sourceHeight", elem.outerHeight())
            elem.animate({
                height: 0,
            }, isInstant ? 0 : animationTime * 1000)
            elem.addClass(activeClass)
        }

        $(".section__header-collapse").off("click").on("click", function() {
            const parent = $(this).parent(".section__header").parent(".form-section")
            const col = parent.children(".container-wrapper")                
            const isCollapsed = col.hasClass(activeClass)

            if (isCollapsed) {
                col.animate({
                    height: col.prop("sourceHeight")
                }, animationTime / 2 * 1000)

                timer.create("restoreHeight", animationTime, 1, () => {
                    col.css("height", "auto")
                })
                col.removeClass(activeClass)
            } else {
                collapse(col)
            }

            context[parent.attr("id")] = !isCollapsed

            localStorage.setItem("collapsedItems", JSON.stringify(context))
        })

        $(".section__header").each(function() {
            if ($(this).attr("initialized")) return
            
            const parent = $(this).parent(".form-section")
            if (context[parent.attr("id")]) {
                collapse(parent.children(".container-wrapper"), true)
            }

            $(this).attr("initialized", "true")
        })
    }

    /**
     * Initializes the document after it has finished loading.
     * 
     * @return {Promise<void>}
     */
    async _onDocumentReady() {
        this.updatedValues = {}
        this.isFormDisabled = false

        this.updateHints()

        await this.retrieveAccountInfo()

        this.compile({
            renderSidebar: true,
            listenButtonHandlers: true,
            mutationObservers: true,
            getDealsPresence: true,
            hookSectionCollapsible: true
        })

        // Checking if user has switched to another account and forcing him to reload the page
        timer.create("sessionCheck", 10, 0, () => {
            this.retrieveAccountInfo(true)
        })

        timer.simple(0, () => {
            this.adjustDOMDatepicker()
        })

        $(".structure").css("opacity", 1)
    }

    initialize() {

    }

    retrieveID(element) {
        return element.attr("name");
    }

    getCategorySuffix() {
        return this.category === "category_lender" ? "_lender" : ""
    }

    getSuffix() {
        return this.getCategorySuffix()
    }

    callCompute(id) {
        const computeValue = this.compute[id]
        if (computeValue != null) {
            let computedValue = computeValue.call(this);
            if (typeof computedValue === "number" && (!isFinite(computedValue) || isNaN(computedValue))) {
                computedValue = 0
            }

            this.storage[id] = computedValue

            return computedValue;
        };

        return;
    }

    getCalculatedId(id) {
        let blacklist = {}
        if (this.collateralData) {
            blacklist = this.collateralData
        }

        blacklist["deal_id"] = true
        blacklist["deal_date"] = true
        blacklist["deal_status"] = true
        blacklist["vehicle_id"] = true
        blacklist["customer_id"] = true
        blacklist["cobuyer_id"] = true

        if (typeof blacklist[id] === "undefined") {
            id += this.getCategorySuffix()
        }

        return id
    }

    getStoredValue(id, recursive, defaultValue) {
        id = this.getCalculatedId(id)

        if (!recursive) {
            this.callCompute(id)
        }

        if (this.storage[id] == null) {
            if (defaultValue != null) {
                return defaultValue
            }

            return null
        }
    
        return this.storage[id]
    }

    formatInput(element) {
        if (!element[0]) {
            return
        }

        if (element.hasClass("input__calendar")) {
            return
        }

        if (element.attr("no-format")) {
            return
        }

        const id = element.attr("name")
        const sourceType = element.attr("source-type")
        const renderFunction = this.views[id]

        if (!sourceType) {
            const inputType = element.attr("type")
            if (!inputType) {
                throw new Error("Missing input type for element with name: " + id)
            }

            element.attr("source-type", inputType)

            const storageValue = this.storage[element.attr("name")]
            if (storageValue !== null) {
                element.attr("value", storageValue)
                element.val(storageValue)
                this.formatInput(element)
            };
        };

        element.attr("type", "text")

        if (renderFunction) {
            element.val(renderFunction.call(this))
            return
        }

        if (element.attr("source-type") !== "text") {
            let number = Number(element.attr("value"))
            if (isNaN(number)) {
                number = 0
            }

            let val = this.formatMoney(number)
            if (element.hasClass("input-percent")) {
                val = this.formatPercent(Number(element.attr("value")))
            } else if (element.hasClass("input-year")) {
                val = String(element.attr("value") || 0) + " yr"
            } else if (element.hasClass("input-number")) {
                val = Math.floor(number)
            } else if (element.hasClass("input-number-float")) {
                val = this.formatNumber(number)
            }

            if (element.is("span")) {
                element.html(val)
            } else {
                element.val(val)
            }
        } else {
            element.val(element.attr("value"))
        }
    }

    hideDatepicker() {
        if (!this.lastDatepicker) return

        $(this.lastDatepicker).trigger("blur")
        this.lastDatepicker = null
    }
    
    removeComboElements() {
        $(".options-outer").remove()
        $("options").remove()
        window.storedCustomElements = {}
    }

    hookCustomElementsEvent() {
        $(".window__panel, .popup-wrapper, .wrapper").on("click", () => {
            this.removeComboElements()

            this.lastDatepicker = null
        })
    }

    hookCalendars() {
        const self = this

        $(".calendar, .input__calendar-element").off("click").on("click", function(event) {
            event.preventDefault()

            const input = $(this)

            if (input.is(self.lastDatepicker)) {
                self.hideDatepicker()
            } else {
                if (input.datepicker("option", "disabled")) {
                    return
                }

                input.datepicker("show")
                self.lastDatepicker = input
            }

            event.stopPropagation()
        })

        $(".calendar__image").off("click").on("click", function(event) {
            try {
                let name = $(this).attr('calendar')
                if (!$(this).attr("perm")) {
                    name += self.getSuffix()
                }
                const input = $(`input[name=${name}]`)

                if (input.is(self.lastDatepicker)) {
                    self.hideDatepicker()
                } else {
                    if (input.datepicker("option", "disabled")) {
                        return
                    }

                    input.datepicker("show")
                    self.lastDatepicker = input
                }
        
                event.stopPropagation()
            } catch (err) {
                console.log(err)
            }
        })
    }

    writeValues() {
        this.customElements()

        const self = this

        const customFields = this.storage.custom_fields
        if (customFields) {
            for (let i = 0; i < customFields.length; i++) {
                const item = customFields[i]
                this.storage["customfield_" + item.field_id] = item.value
            }
        }

        $(".input__entry, .input__textarea, [text-editable]").each(function() {
            const idName = self.retrieveID($(this))
            const storedValue = self.storage[idName]

            if (typeof storedValue !== "undefined") {
                self.writeValue($(this), storedValue)
            }

            self.formatInput($(this))
        })
    
        const switchHandlers = $("ctm-checkbox, .switcher")
        switchHandlers.each(function() {
            const value = self.storage[$(this).attr("name")]
            if (typeof value === "undefined") {
                return
            }

            const className = "checked"

            $(this).prop("checked", value)
            if (value) {
                $(this).addClass(className)
            } else [
                $(this).removeClass(className)
            ]
            
            $(this).trigger("change", [true])
        })

        $("ctm-select").each(function() {
            const value = self.storage[$(this).attr("name")]
            if (!value) {
                return
            }

            const options = $(this).prop("options") || []

            const id = options.map(e => e.id).indexOf(String(value))
            const val = id === -1 ? "" : options[id].value

            $(this).html(val)
            $(this).prop("value", value)
            $(this).trigger("change", [true])
        })

        this.hookCustomElementsEvent()
    }

    preInputChanged(...args) {
        hook.call("preInputChanged", ...args)
    }

    postInputChanged(...args) {
        hook.call("postInputChanged", ...args)
    }

    onCheckboxChanged(...args) {
        hook.call("onCheckboxChanged", ...args)
    }

    preComboChanged(...args) {
        hook.call("preComboChanged", ...args)
    }

    onComboChanged(...args) {
        hook.call("onComboChanged", ...args)
    }

    normalizeInputs() {}

    onPopupOpened() {}

    async loadBadVinReport(vin) {
        notify.generic("Vehicle report generation has started!", 10)
        const res = await this.request("POST", "/badvin?vin=" + vin)
        res.receive(() => {
            const id = res.reportId
            notify.success(`Vehicle report has been successfully generated!<br><a href="/badvin/${id}" target="_blank">View report</a>`, 30)
        }, {
            406: "You have not provided VIN for this request!",
            407: "The request is being processed, please wait!",
            502: () => {
                notify.error(res.error || "Error 502 has occured on backend! Please report this to the support team", 10)
            }
        })
    }

    async loadCustomFields(element, category, id) {
        if (!this.customFieldsInfo) {
            const res = await this.request("GET", "/account/customfields?category=" + category + "&id=" + id)

            this.customFieldsInfo = res.payload
        }

        const payload = this.customFieldsInfo

        $(".main-section").css("opacity", 1)

        if (payload.length === 0) {
            $("#customfields").remove()
            return
        }

        for (let i = 0; i < payload.length; i++) {
            const item = payload[i]
            const optionsList = item.options
            const fieldId = "customfield_" + item.field_id

            let html = ""
            switch (item.type) {
                case "combo": {
                    let optionsHTML = ""
                    for (let j = 0; j < optionsList.length; j++) {
                        optionsHTML += `<ctm-option value="${optionsList[j].id}">${optionsList[j].value}</ctm-option>`
                    }
                    html = /*html*/`
                        <div class="input">
                            <span class="input__text --column">${item.name}</span>
                            <ctm-select name="${fieldId}" index="${item.field_id}" class="input__combobox sz-xl">
                                ${optionsHTML}
                            </ctm-select>
                        </div>
                    `
                    break
                }
                case "input": {
                    let inputType = "text"
                    let formatClass = ""
                    let emailAttr = ""
                    
                    switch (item.format) {
                        case "number": {
                            inputType = "number"
                            formatClass = "input-number"
                            break
                        }
                        case "money": {
                            inputType = "number"
                            break
                        }
                        case "percent": {
                            inputType = "number"
                            formatClass = "input-percent"
                            break
                        }
                        case "phone": {
                            inputType = "number"
                            this.views[fieldId] = function() {
                                return this.formatPhoneNumber(this.getStoredValue(fieldId, true));
                            }
                            break
                        }
                        case "email": {
                            inputType = "text"
                            emailAttr = `email="true"`
                            break
                        }
                    }

                    html = /*html*/`
                        <div class="input">
                            <span class="input__text input__text--column">${item.name}</span>
                            <input value="" index="${item.field_id}" ${emailAttr} type="${inputType}" name="${fieldId}" class="input__entry sz-xl ${formatClass}">
                        </div>
                    `

                    this.trackEmailAndPhones()

                    break
                }
                case "checkbox": {
                    html = /*html*/`
                        <div class="input">
                            <span for="customer_account_address" class="input__text input__text--column"></span>
                            <div class="checkbox">
                                <ctm-checkbox name="${fieldId}" index="${item.field_id}" class="checkbox__element"></ctm-checkbox>
                                <span class="checkbox__text">${item.name}</span>
                            </div>
                        </div>
                    `
                }
            }

            element.append(html)
        }
        
        this.normalizeInputs()
    }

    saveCustomFields(element, idName, value) {
        if (idName.search("customfield") !== -1) {
            const field_id = Number(element.attr("index"))
            if (!this.storage.custom_fields) {
                this.storage.custom_fields = []
            }
            const index = this.storage.custom_fields.map(e => e.field_id).indexOf(field_id)
            const data = {
                field_id: field_id,
                value: value
            }
            
            if (index !== -1) {
                this.storage.custom_fields[index].value = value
            } else {
                this.storage.custom_fields.push(data)
            }
        }
    }

    async showFilters(title = "Search Filters", handlerId = "applyFilters") {
        const payload = this.account
        let dealTypes = ""
        for (let i = 0; i < payload.permissions.length; i++) {
            const dealType = payload.permissions[i]
            dealTypes += `<div class="input__radio-option" type="${dealType}">${dealType}</div>`
        }
        
        const Filters = await this.template("deal/SearchFilters", {
            dealTypes: dealTypes,
            handlerId: handlerId
        })
        
        this.showWindow(title)
        this.setWindowClass("window-auto")
        this.setWindowHTML(Filters)

        this.hookInputs()
        this.writeValues()
    }

    datepickerInitialize() {
        if (this.isDatepickerInitiliazed) return
        this.isDatepickerInitiliazed = true
        
        const testId = "4864185246"
        $("body").append(`<input id="${testId}" style="display: none;"></input>`)
        const element = $("#" + testId)
        if (!element.datepicker) {
            return
        }

        element.datepicker({})
        
        const calendar = $("#ui-datepicker-div")
        calendar.css("display", "none")
        calendar.on("click", (event) => {
            event.stopPropagation()
        })
    }

    addWindowListener() {
        const func = (isModal) => {
            this.removeSelection()
            if (window.clickStartedElement !== window.clickEndedElement) {
                return
            }

            this.removePopup(isModal)
        }

        $(window).on("click", () => {
            func()
        })
        
        $(".window__panel, .window__panel-2, .popup-window").on("click", (e) => {
            func(true)
            
            e.stopPropagation()
        })
    }

    adjustDOMDatepicker() {
        if (!this.customScrollBehavior) return

        const datepicker = $("#ui-datepicker-div")
        datepicker.appendTo(".calendar-outer")
    }

    setupDatepicker(datepickerElement, skeleton) {
        const limit = datepickerElement.attr("limit")

        if (!skeleton) {
            skeleton = {}
        }

        if (limit === "max") {
            skeleton["maxDate"] = new Date()
        } else if (!isNaN(limit)) {
            let date = new Date(this.storage["deal_date"]).getTime() + Number(limit) * 3600 * 24 * 1000
            skeleton["minDate"] = new Date(date)
        }  else if (limit !== "no") {
            skeleton["minDate"] = new Date()
        }

        skeleton.dayNamesMin = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        skeleton.inline = true
        skeleton.showOtherMonths = true
        skeleton.dateFormat = "mm/dd/yy"
        skeleton.firstDay = 1
        skeleton.changeYear = true
        skeleton.yearRange = "c-30:c+30"
        skeleton.onClose = () => {
            timer.simple(0.1, () => {
                this.lastDatepicker = null
            })
        }

        return skeleton
    }

    setupDatepickers(shouldDisable) {
        for (let i = 0; i < this.datepickers.length; i++) {
            const idName = this.getCalculatedId(this.datepickers[i])
            const datepickerElement = $(`input[name=${idName}]`)

            if (!datepickerElement[0]) continue

            const dtParams = {}
            dtParams.onSelect = (dateText) => {
                const selectedDate = new Date(dateText.replaceAll(".", "/"))

                if (!cf.isEqualDates(selectedDate, new Date(this.storage[idName]))) {
                    this.onUpdate(this.updateID, datepickerElement)
                }

                this.storage[idName] = selectedDate.getTime()
                hook.call("onDateSelected", selectedDate, idName, datepickerElement)
                
                this.compile({
                    normalizeInputs: [null, true],
                    hideDatepicker: true
                })

                return true
            }

            // ? This is a hack for datepicker to make it work in my scrolling system
            if (this.customScrollBehavior) {
                dtParams.beforeShow = (input, inst) => {
                    timer.simple(0, () => {
                        const element = inst.dpDiv
    
                        const position = $("input[name=" + input.getAttribute("name") + "]").offset()
    
                        const [ox, oy] = this.getCurrentUIOffset(element)
    
                        const headerOffset = this.getHeaderOffset(element)
                        const outerOffset = $(".calendar-outer").offset() || {}
    
                        const xPos = Math.min(position.left - ox - (outerOffset.left || 0), $(window).width() - element.width() - 10)
                        const yPos = Math.min(position.top + oy + headerOffset, $(window).height() - element.height() - 10 + $(document).scrollTop()) - (outerOffset.top || 0) + 32
    
                        element.css({
                            "position": "absolute",
                            "left": xPos,
                            "top": yPos
                        })
                    })
                }
            }
            
            const datepickerSkeleton = this.setupDatepicker(datepickerElement, dtParams)
            const dateObject = new Date(Number(this.storage[idName]))

            if (dateObject && shouldDisable) {
                datepickerSkeleton.minDate = dateObject
                datepickerSkeleton.maxDate = dateObject
            }

            const isViewLocked = datepickerElement.attr("view-lock")

            if (isViewLocked) {
                datepickerElement.datepicker("hide")
                datepickerElement.datepicker("destroy")
            }

            datepickerElement.datepicker(datepickerSkeleton)

            if (!isViewLocked && shouldDisable) {
                datepickerElement.datepicker("option", "disabled", true)
            }

            if (!isNaN(dateObject)) {
                datepickerElement.datepicker("setDate", dateObject)
            }
        }

        this.datepickerInitialize()
    }

    hookInputs() {
        const self = this

        const inputs = $(".input__entry, .input__textarea")
        inputs.on("blur", function(event) {
            if ($(this).attr("active") === "false") {
                return
            }

            if ($(this).attr("light-mode")) {
                return
            }

            let idName = $(this).attr("name");
            if (!idName) {
                return
            }

            const sourceType = $(this).attr("source-type") || $(this).attr("type")

            let value = $(this).val()
    
            if (value === "" && sourceType === "number") {
                $(this).attr("value", "")
                if (self.storage[idName] !== null && typeof self.storage[idName] !== "undefined") {
                    self.onUpdate(self.updateID, $(this))
                }

                self.storage[idName] = null
                self.formatInput($(this));
            } else {
                if (sourceType === "text") {
                    value = value.replace(/[^\x00-\x7F]/g, "")
                } else {
                    value = String(value).replaceAll(/[^0-9, ^.]/g, "").replaceAll(" ", "").replaceAll(",", "").replaceAll("^", "")
                }

                const phone = $(this).attr("phone")
                if (phone) {
                    value = value.replace(".", "")
                }

                if (sourceType === "number" && $(this).attr("noformat") !== "true" && !phone) {
                    value = Number(value)

                    if (isNaN(value)) {
                        value = 0
                    }
                } else {
                    value = value.trim()
                }
                
                value = self.writeValue($(this), value)

                let idName = $(this).attr("name");

                if (self.canElementCauseUpdate($(this))) {
                    if (typeof self.storage[idName] === "undefined") {
                        if (value !== 0 && value !== "") {
                            self.onUpdate(self.updateID, $(this))
                        }
                    } else {

                        if (self.storage[idName] != value) {
                            self.onUpdate(self.updateID, $(this))
                        }
                    }
                }

                const returnedValue = self.preInputChanged($(this), idName, value)
                if (typeof returnedValue !== "undefined") {
                    value = returnedValue
                }

                self.storage[idName] = value

                $(`input[name=${idName}]`).each(function() {
                    self.writeValue($(this), value)
                });

                self.postInputChanged($(this), idName, value)
                self.saveCustomFields($(this), idName, value)
                
                self.normalizeInputs(true)
            }

            $(this).attr("active", "false")
        })

        const onInputEntered = (element, event, select) => {
            if (element.attr("disabled")) {
                return
            }

            if (element.attr("active") === "true") {
                return
            }

            console.log("YES")

            const sourceType = element.attr("source-type")
            const insertValue = () => {
                if (sourceType == "number" && sourceType != element.attr("type")) {
                    const value = element.attr("value")
                    if ((element.attr("phone") || element.attr("ssn")) && value) return

                    element.val(value)

                    if (!value || value == 0) {
                        element.trigger("select", [true])
                    }
                };
            }

            insertValue()

            element.attr("active", "true")
            event.preventDefault()            
        }

        inputs.off("click.base").on("click.base", function(event) {
            timer.simple(0.05, () => {
                onInputEntered($(this), event)
            })
        })

        inputs.off("select.base").on("select.base", function(event, fromScript) {
            if (fromScript) return
            timer.simple(0.1, () => {
                onInputEntered($(this), event, true)
            })
        })

        $(".input__calendar").on("blur", function() {
            const idName = $(this).attr("name")
            const value = $(this).val()

            const dateTime = new Date(value).getTime()

            if (isNaN(dateTime)) return

            if (self.storage[idName] != dateTime && value !== "") {
                self.onUpdate(self.updateID, $(this))
            }

            hook.call("onDateSelected", dateTime, idName, $(this))

            self.storage[idName] = dateTime
        })

        const switchHandlers = $("ctm-checkbox, .switcher")
        switchHandlers.off("change")
        switchHandlers.on("change", function(event, init) {
            const checkedClass = "checked",
                element = $(this),
                idName = element.attr("name"),
                section = element.attr("section");

            let isChecked = $(this).prop(checkedClass);

            if (section && !init) {
                isChecked = true
                $(this).addClass(checkedClass)
                $(`ctm-checkbox[section=${section}]`).each(function() {
                    if ($(this).is(element)) {
                        return
                    }

                    $(this).prop(checkedClass, false);
                    $(this).removeClass(checkedClass)
                    if (!init) {
                        self.storage[$(this).attr("name")] = false
                    }
                })
            }

            self.onCheckboxChanged($(this), idName, isChecked)
            self.saveCustomFields($(this), idName, isChecked)

            if (!init && !$(this).attr("static")) {
                if (self.canElementCauseUpdate($(this)) && self.storage[idName] != isChecked) {
                    self.onUpdate(self.updateID, $(this))
                }

                self.storage[idName] = isChecked
                self.normalizeInputs(null, true)
            }
        })

        $("ctm-select").off("change")
        $("ctm-select").on("change", function(event, init) {
            const idName = $(this).attr("name")
            const value = $(this).prop("value")

            if (!init) {
                self.preComboChanged($(this), idName, value)
            }

            self.storage[idName] = value

            if (!init) {
                if (self.canElementCauseUpdate($(this))) {
                    self.onUpdate(self.updateID, $(this))
                }

                self.saveCustomFields($(this), idName, value)
                self.computeInputs()
                self.writeValues()
                self.onComboChanged($(this), idName, value)
            }
        })

        const radioButtons = $(".input__radio-option")
        const assignRadio = () => {
            radioButtons.each(function() {
                const idName = $(this).parent(".input__radio").attr("name")
                const activeClass = "radio-active"
                if (($(this).attr("type") || "") === (self.storage[idName] || "")) {
                    $(this).addClass(activeClass)
                } else {
                    $(this).removeClass(activeClass)
                }
            })
        }

        radioButtons.on("click", function() {
            const idName = $(this).parent(".input__radio").attr("name")
            const value = $(this).attr("type") || ""
            self.storage[idName] = value

            hook.call("onRadioChanged", value, idName)

            assignRadio()
        })
        
        assignRadio()
    }

    removeUpdates() {
        $("input, ctm-select, ctm-checkbox").each(function () {
            $(this).attr("no-update", "true")
        })
    }

    canElementCauseUpdate(element) {
        return !element.attr("no-update") && !element.attr("perm")
    }

    onUpdate(id, element) {
        id = id || "#unsaved-deal"
        
        if (this.noUpdateState) return
        $(id).addClass("msg-show")

        this._updated = true

        if (element) {
            const className = "element-updated"
            if (element.addClass) {
                // element.addClass(className)
                this.updatedValues[element.attr("name")] = true
            } else {
                // element.classList.add(className)
                this.updatedValues[element.getAttribute("name")] = true
            }   
        }
    }

    clearUpdates() {
        $("*").removeClass("element-updated")
        this.updatedValues = {}
        this._updated = false
    }

    onSave(id, omit) {
        const error = this.formHasError()
        if (error) {
            return true
        }

        $(id).removeClass("msg-show")
        this.clearUpdates()

        if (!omit) {
            notify.success("You have succesfully saved all changes!", 5)
        }
    }

    updateStatusMark(element, status) {
        status = status || this.storage.deal_status 
        element = element || $(".deal__status")

        element.removeClass("status-good status-med status-bad")
    
        if (status === "deal_status_accepted" || status === "deal_status_infunding" || status === "deal_status_funded" || status === "deal_status_countered" || status === "deal_status_contract_requested") {
            element.addClass("status-good")
        } else if (status === "deal_status_denied" || status === "deal_status_removed" || status === "deal_status_dumped") {
            element.addClass("status-bad")
        } else if (status === "deal_status_application" || status === "deal_status_preapproved") {
            element.addClass("status-med")
        }
    }

    writeValue(element, value) {
        if (typeof value === "number" && !isNaN(parseInt(value)) && !element.hasClass("input__text")) {
            value = Math.max(Number(element.attr("min")) || 0, value)
        }

        const type = element.attr("source-type")
        if (type === "number" && !element.attr("phone")) {
            if (value == null) {
                value = 0
            }
        }

        element.attr("value", value)
        this.formatInput(element)

        return value
    }

    computeInputs() {
        const self = this;
        $("input[name], .input__text[name]").each(function() {
            const name = $(this).attr("name")
            const computedValue = self.callCompute(name)
            if (computedValue != null) {
                self.writeValue($(this), computedValue)
            }
        })
    }
    
    compile(pipeline) {
        for (const [service, args] of Object.entries(pipeline)) {
            let transArgs = args
            if (args === true) {
                transArgs = []
            }
            
            try {
                this[service](...transArgs)
            } catch(err) {
                console.log("Service: [" + service + "]\n", err)
            }
        }
    }

   addDatepicker(id) {
        const self = this
        let element = $("#" + id)
        element.datepicker({
            inline: true,
            showOtherMonths: true,
            dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            onSelect: (dateText) => {
                self.storage[id] = new Date(dateText).getTime();
            }
        });
    }

    findLatest(array, year, month, day, filter) {
        let index = -1
        
        for (let i = 0; i < array.length; i++) {
            const obj = array[i]

            if (typeof filter === "function" && filter(obj) === false) {
                continue
            }

            if (year > obj.year) {
                index = i
            } else if (year === obj.year) {
                if (month > obj.month) {
                    index = i
                } else if (month === obj.month) {
                    if (day >= obj.day) {
                        index = i
                    }
                }
            }
        }
    
        return index
    }

    async retrieveAccountInfo(checker) {
        const res = await this.request("GET", checker ? "/account/info/debug" : "/account/info")

        const payload = res.payload || {},
            context = res.context || {}

        if (payload && this.account && this.account.user_id !== payload.user_id && !this.authFree) {
            menu.create("Authentication", null, true)
            menu.setWindowClass("window-auto")
            menu.setHTML(await net.template("SessionUpdate", {
                currentPath: window.location.href
            }))

            timer.remove("sessionCheck")
        }
        
        if (context.isTechnicalWorks && !checker) {
            $(".wrapper").append(await this.template("TechnicalWorks"))
        }

        this.account = payload

        return res
    }

    // Backwards compatibility
    async request(type, url, success, error) {
        const response = await net.request(type, url, success, error)
        return response
    }
    async json(method, url, data) {
        const response = await net.json(method, url, data)
        return response
    }
    async formData(method, url, data) {
        const response = await net.formData(method, url, data)
        return response
    }
    async template(path, params, success) {
        const response = await net.template(path, params, success)
        return response
    }

    hookSearchInput(category, callback, newUrl) {
        const self = this,
            searchInput = $("#search"),
            searchButton = $("#search-button")

        searchInput.off().on("keypress", function(e) {
            if (e.key !== "Enter") {
                return
            }
            $(this).trigger("blur")
            searchButton.trigger("click")
        })

        searchButton.off().on("click", async function() {
            let path = window.location.pathname
            if (typeof category !== "undefined" && category !== null) {
                path = path + category
            }

            const value = searchInput.val()
            const params = getCommonParams({
                search: value
            })

            self.setCustomParam("search", value)

            let url = path + params
            if (newUrl) {
                url = newUrl + params
            }

            const res = await self.request("GET", url)

            if (callback) {
                callback(res)
            } else {
                window.location.replace(url)
            }
        })
    }

    hookPagination(res, baseURL, callback) {                        
        const Pag = new Paginator()
        
        const initPug = (res) => {
            callback(res)

            Pag.init(res.pagination, async (page) => {
                this.setCustomParam("page", page)
                const res = await this.request("GET", baseURL + this.getCustomParams())
                callback(res)
            })

            this.hookInputs()
            this.writeValues()
        }

        hook.add("onComboChanged", "maxItemsChanged", async (element, idName, value) => {
            if (idName !== "max_items") return
            this.setCustomParam("max_items", value)

            const res = await this.request("GET", baseURL + this.getCustomParams())
            initPug(res)
        })

        this.resetParams()

        initPug(res)
    }

    hookSortableColumns(endpoint, callback) {
        const urlParams = new URLSearchParams(window.location.search)
        const self = this,
            urlEndpoint = endpoint || window.location.pathname,
            listHeaderElement = $(".header__name, .list__text");

        listHeaderElement.off().on("click", async function() {
            const sortOrder = endpoint ? self.getCustomParam("sort_order") : urlParams.get("sort_order"),
                sortId = endpoint ? self.getCustomParam("sort_id"): urlParams.get("sort_id");

            const sort_id = $(this).attr("type")
            if (!sort_id) {
                return
            }

            let sort_order = sortOrder === "asc" ? "desc" : "asc"
            if (sortId !== sort_id) {
                sort_order = "asc"
            }

            const params = {
                sort_id: sort_id,
                sort_order: sort_order
            }

            if (endpoint) {
                self.setCustomParam("sort_id", sort_id)
                self.setCustomParam("sort_order", sort_order)
            }

            listHeaderElement.removeClass(self.sortableClassConfig)
            if (sortOrder && sortId === sort_id && sort_order === "asc") {
                params.sort_id = ""
                params.sort_order = ""

                if (endpoint) {
                    self.setCustomParam("sort_id", "")
                    self.setCustomParam("sort_order", "")
                }
            } else {
                $(this).addClass("sort-column " + sort_order)
            }

            const url = urlEndpoint + (endpoint ? self.getCustomParams() : getCommonParams(params)) 
            
            if (endpoint) {
                const res = await self.request("GET", url)

                callback(res)
            } else {
                window.location.replace(url)
            }

            self.applySortableClasses(endpoint)
        })
        
        this.applySortableClasses(endpoint)
    }

    applySortableClasses(endpoint) {
        const self              = this,
            urlParams           = new URLSearchParams(window.location.search),
            listHeaderElement   = $(".header__name, .list__text");


        const sortOrder = endpoint ? self.getCustomParam("sort_order") : urlParams.get("sort_order"),
            sortId      = endpoint ? self.getCustomParam("sort_id") : urlParams.get("sort_id");

        let defaultId;

        listHeaderElement.each(function() {
            const type = $(this).attr("type")
            if (type && type.search("_id") !== -1) {
                defaultId = type
            }
        })

        const sort_order = sortOrder || "desc"
        const sort_id = sortId || defaultId
        listHeaderElement.each(function() {
            const type = $(this).attr("type")
            if (sort_id === type) {
                $(this).addClass("sort-column " + sort_order)
            } else {
                $(this).removeClass(self.sortableClassConfig)
            }
        })
    }

    async requestVINInformation(vin, onLoaded) {
        const self = this

        this.load()

        const res = await this.request("GET", "/jdvin?vin=" + vin)
        if (res.error) {
            notify.error(res.error, 7)
            this.finishLoad()
            return
        }
        
        const payload = res.payload
        
        this.showWindow("Select Vehicle", true)
        this.setWindowHTML(`
            <div class="container" style="display: flex; flex-direction: column; width: 100%;">
            </div>
        `, true)

        this.finishLoad()

        this.storage.jd_trim_selection = {}

        let useTrimId = "trim"
        let lastTrim2;
        // ! implement better logic with N/A in a row check
        for (const [key, value] of Object.entries(payload)) {
            if (value.trim2 !== "N/A") continue

            if (value.trim2 !== lastTrim2 && lastTrim2) {
                useTrimId = "trim2"
                break
            }

            lastTrim2 = value.trim2
        }

        for (const [key, value] of Object.entries(payload)) {
            const trim = value[useTrimId]
            this.storage.jd_trim_selection[trim] = value.ucgvehicleid

            const element = `
                <div class="vehicle-item" vehicle-id="${value.ucgvehicleid}" index="${key}">
                    <div class="vehicle-icon"></div>
                    <div class="vehicle-text">
                        <div class="vehicle-title">${value.modelyear + " " + value.make + " " + value.model}</div>
                        <div class="vehicle-body">${value.body}</div>
                    </div>
                </div>
            `
            $(".container").append(element)
        }

        this.storage.jd_trim_selection = JSON.stringify(this.storage.jd_trim_selection)

        this.removeInputError($("input[name=vehicle_vin]"))
        
        $(".vehicle-item").on("click", async function() {
            const ugc_vehicleid = $(this).attr("vehicle-id")

            const jdFieldsMap = {
                modelyear: "vehicle_year",
                make: "vehicle_mark",
                model: "vehicle_model",
            }

            const index = Number($(this).attr("index"))

            const item = payload[index]

            const info = {}
            for (const [key, value] of Object.entries(item)) {
                const idFromMap = jdFieldsMap[key]
                if (!idFromMap) continue

                self.storage[idFromMap] = value
                info[idFromMap] = value
            }

            self.storage.ucg_vehicleid = ugc_vehicleid
            self.storage.vehicle_trim = item[useTrimId]

            info.vehicle_vin = vin
            await self.loadJDValues(ugc_vehicleid, null, self.storage.jdpower_period, info)

            if (onLoaded) {
                onLoaded()
            }
        })        
    }

    loadJDValues(ugc_vehicleid, mileage, period, info) {
        return new Promise(async (resolve, reject) => {
            mileage = mileage || 0
            period = period || 0
    
            if (!ugc_vehicleid) {
                this.finishLoad()
                return
            }
    
            this.load()
            const res = await this.request("GET", "/jdvalues?ucg_vehicleid=" + ugc_vehicleid + "&mileage=" + mileage + "&period=" + period)
            if (res.error) {
                notify.error(res.error, 5)
                this.finishLoad()
                return
            }
    
            const payload = res.payload

            for (const [key, value] of Object.entries(payload)) {
                let newValue = value
                if (value < 0) {
                    newValue = -value
                }
                this.storage[key] = newValue
            }

            if (this.storage["vehicle_id"]) {
                this.updateAllValues()
                this.finishLoad()
                this.onUpdate("#unsaved-other")

                resolve()
            } else {
                for (const [key, value] of Object.entries(info)) {
                    payload[key] = value
                }
    
                const res = await this.json("POST", "/dealer/inventory/post", {
                    payload: payload
                })
    
                this.finishLoad()

                resolve(res.code)
            }
    
            this.closeWindow("-2")
        })
    }
    
    async refreshReport(id, element, small) {
        this.load(element, small)
        const res = await this.request("POST", "/customers/" + id + "/bank")
        this.finishLoad()
        if (res.error) {
            notify.error(res.error, 7)
        } else {
            notify.success("Report was successfully generated!", 7)
            const field = "customer_reported_income"
            const input = $("#" + field)
            this.storage[field] = res.payload
            input.attr("value", this.storage[field])
            this.formatInput(input)
        }
    }

    async loadReports(id) {
        const self = this
        if (typeof user_type !== "undefined") {
            this.load(".list__wrapper")
        } else {
            this.load()
        }

        const formatTime = (time) => {
            return time < 10 ? "0" + String(time) : time
        }

        const res = await this.request("GET", "/customers/" + id + "/reports")

        res.receive(async () => {
            let payload = res.payload || []
            payload = payload.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    
            this.showWindow("Bank Reports", true)
            this.setWindowPanelClass("popup-column", true)
    
            let html = ""
    
            for (let i = 0; i < payload.length; i++) {
                const item = payload[i]
                const pdfItem = item.key,
                    date = new Date(item.date),
                    realKey = pdfItem.replace(".xlsx", "");
                    
                html += await this.template("customer/PDFItem", {
                    customer_id: id,
                    id: pdfItem,
                    key: realKey,
                    date: "Report for " + " " + date.getDate() + " " + this.months[date.getMonth()] + " " + date.getFullYear() + " - " + formatTime(date.getHours()) + ":" + formatTime(date.getMinutes())
                })
            }
    
            this.setWindowHTML(html, true)
    
            $(".selection-remove").on("click", async function(event) {
                event.stopPropagation()
    
                const key = $(this).attr("key-id")
                const fileName = $(this).attr("item-id")
                $(`.selection__item[key-id=${key}]`).remove()
                self.load(".list__wrapper")
                await self.request("DELETE", "/customers/" + id + "/report/" + fileName)
                self.finishLoad()
            })
        }, {
            502: "Information was updated! Please reload page"
        })

        this.finishLoad()
    }

    async sendLink(id, element, small) {
        this.load(element || "#sidebtn-customers-link", small)
        const res = await this.request("POST", "/customers/" + id + "/link")

        this.finishLoad()
        if (res.error) {
            notify.error(res.error, 10)
        } else {
            notify.success("An email was sent to user with integration link!", 7)
        }
    }

    async contracts(dealId, vehicleCost, dealType) {
        const self = this
        const deal_id = dealId ? dealId : this.storage.deal_id
        const deal_type = dealType ? dealType : self.storage.deal_type
        this.load()

        this.finishLoad()

        const html = await Contracts.render({ dealId: deal_id, dealType: deal_type, vehicleCost: vehicleCost, ctx: this })
        this.showWindow(`Print Contracts (Deal #${deal_id})`)
        this.setWindowClass("window-auto")
        this.setWindowHTML(html)

        this.hookListToggleButton()
    }
    
    async viewLenders(dealId) {
        const section = $("#main-section")
        section.css("opacity", "0")
        section.html("")
        this.load()
        const res = await this.request("GET", "/lenders?deal_id=" + dealId)
        section.css("opacity", "1")
        this.finishLoad()
        section.html(await Lenders.render({ viewType: "view", response: res, ctx: this }))
    }

    async submitLenders(dealId) {
        this.load()
        const res = await this.request("GET", "/lenders?deal_id=" + dealId)
        this.finishLoad()

        this.showWindow("Lender submission")
        this.setWindowHTML(await Lenders.render({ viewType: "manage", response: res, ctx: this }))
        this.setWindowClass("window-auto")
                
        this.hookListToggleButton()
    }

    async showStipulations(deal_id, user_type, category) {
        category = category || null
        this.load()
        this.showWindow(`Approval Documents (Deal #${deal_id})`)
        this.setWindowClass("window-huge")

        const res = await this.request("GET", "/deals/" + deal_id + "/upload")
        this.finishLoad()

        const html = await Stipulations.render({ documents: res.info, dealId: deal_id, userType: user_type, category: category })
        this.setWindowHTML(html)
    }

    async checkSave() {
        return new Promise(async (resolve) => {
            if (this.updated) {
                const html = await this.template("SaveWarning")
                this.showWindow("Warning", true)
                this.setWindowClass("window-auto", true)
                await this.setWindowRender(html, true)
    
                $("#proceed-btn").on("click", () => {
                    this.closeWindow("-2")
                    resolve()
                })
            } else {
                resolve()
            }
        })
    }
    
    cachePopups() {
        const self = this
        timer.simple(0.1, () => {
            $(".input__dots").each(async function() {
                self.template(`popups/${$(this).attr("popup-type")}`)
            })
        })
    }

    hookListToggleButton() {
        const button = $("#btn-toggle")
        const defaultText = "Select All"
        button.html(defaultText)
        button.on("click", function() {
            const checked = !$(this).prop("checked")

            if (checked) {
                $(this).html("De" + defaultText)
            } else {
                $(this).html(defaultText)
            }

            $(this).prop("checked", checked)
            $(".print-checkbox").each(function() {
                if ($(this).attr("disabled")) {
                    return
                }

                $(this).prop("checked", checked)

                if (checked) {
                    $(this).addClass("checked")
                } else {
                    $(this).removeClass("checked")
                }
            })
        })
    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    addRemoveButton(buttonId, endpoint, urlOrCallback) {
        let element = $("#" + buttonId)
        if (element.length === 0) {
            element = $(buttonId)
        }

        element.off().on("click", () => {
            this.onRemovePressed(endpoint, urlOrCallback)
        })
    }

    async onRemovePressed(endpoint, urlOrCallback, overrideMethod, onLoad) {
        if (typeof urlOrCallback === "string") {
            urlOrCallback = this.getURLWithSavedParams(urlOrCallback)
        }
        
        await cf.onRemovePressed(endpoint, urlOrCallback, overrideMethod, onLoad)
    }

    async showConfirmationWindow(text, callback) {
        const html = await this.template("DeleteWarning", {
            yesText: "Proceed",
            noText: "Return"
        })
        
        this.showWindow("Be advised", true)
        this.setWindowHTML(html, true)
        this.setWindowClass("window-auto", true)

        $(".confirm__text").html(text)

        $(".confirm__no").on("click", () => {
            this.closeWindow("-2")
        })

        $(".confirm__yes").on("click", () => {
            callback()
            this.closeWindow("-2")
        })
    }

    copyToClipboard(str) {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    getHeaderOffset(element) {
        const parents = element.parents()

        let hits = 0
        for (let i = 0; i < parents.length; i++) {
            const className = parents[i].className
            if (className.search("collateral-section") !== -1 || className.search("list__wrapper") !== -1) {
                hits++
            }
        }

        if (hits >= 2) {
            return $(".panel__header").outerHeight(true)
        }

        return 0
    }

    removePopup(isModal) {
        const popupElem = this.popupElem
        if (popupElem) {
            if (isModal && !popupElem.attr("modal")) {
                return
            }

            this.lastButton = null
            popupElem.removeClass("popup-show")
        }
    }

    hookPopup(popupElem, omitSuffix) {
        const self = this

        this.cachePopups()

        popupElem = popupElem || this.popupElem
        this.popupElem = popupElem

        popupElem.off("click").on("click", function(event) {
            event.stopPropagation()
        })

        const updateWrapperWidth = () => {
            const wrapper = $(".popup-wrapper"),
                screenWidth = $(window).width(),
                cssWidth = wrapper.css("width");
            
            if (!cssWidth) return

            let width = wrapper.attr("base-width")
            if (!width) {
                wrapper.attr("base-width", cssWidth)
                width = cssWidth
            }

            let respWidth = Number(width.replace("px", ""))
            if (screenWidth < 1300) {
                respWidth = respWidth * 0.65
            }

            if (screenWidth < config.shared.mobileWidthBreakpoint) {
                respWidth = screenWidth - 10
            }

            wrapper.css("width", respWidth + "px")

            return respWidth
        }

        $(window).on("resize", () => {
            updateWrapperWidth()
        })

        $(".input__dots").off().on("click", async function(event) {
            event.stopPropagation()
            
            self.hideDatepicker()
            self.removeComboElements()

            const element = $(this),
                position = element.offset(),
                posClass = "absolute",
                reference = element.attr("reference-id"),
                popupType = element.attr("popup-type"),
                activeClass = "popup-show",
                sameButton = element.is(self.lastButton),
                shouldToggleClass = !self.lastButton || sameButton,
                parents = element.parents();

            let isModal;
            for (let i = 0; i < parents.length; i++) {
                if (parents[i].className.search("panel__list") !== -1) {
                    isModal = true
                }
            }

            if (isModal) {
                popupElem.attr("modal", "true")
            } else {
                popupElem.removeAttr()
            }

            
            const closePopup = (force) => {
                self.lastButton = null

                if (shouldToggleClass || force) {
                    popupElem.removeClass(activeClass)
                }
            }

            if (sameButton) {
                closePopup()
            } else {
                self.lastButton = element
                const afterLoad = async (html) => {
                    if (reference != null) {
                        html = html.replaceAll("%i", reference)
                    }
                    let address_type = self.lastButton.attr("address-type")
                    if (address_type) {
                        html = html.replaceAll("%s", address_type)
                    }

                    await component.renderHTML(popupElem, html)

                    const wrapper = $(".popup-wrapper")
                    wrapper.prepend(`<div class="popup-close"></div>`)

                    $(".popup-close").on("click", () => {
                        closePopup(true)
                    })

                    if (shouldToggleClass) {
                        popupElem.addClass(activeClass)
                    }

                    self.normalizeInputs(popupType, omitSuffix)

                    const respWidth = updateWrapperWidth()

                    popupElem.css("position", posClass)

                    const [ox, oy] = self.getCurrentUIOffset(popupElem),
                        offsetx = element.outerWidth(),
                        headerOffset = self.getHeaderOffset(element),
                        outerOffset = $("#popup-outer").offset() || {},
                        scrollElement = $(document);


                    const scrollOffset = 10
                    const pos = {
                        x: Math.min(position.left + offsetx - ox - (outerOffset.left || 0), $(window).outerWidth() - respWidth - scrollOffset),
                        y: Math.min(position.top + oy + headerOffset - 3, $(window).outerHeight() - self.popupElem.outerHeight() + scrollElement.scrollTop() - scrollOffset) - (outerOffset.top || 0)
                    }

                    popupElem.css("left", pos.x)
                    popupElem.css("top", pos.y)

                    self.onPopupOpened(popupType)
                }

                const html = await self.template(`popups/${popupType}`)
                await afterLoad(html)
            }
        })

        hook.add("onWindowClosed", "removePopup", () => {
            this.removePopup(true)
        })
    }

    adjustCMSBodyHeight() {
        const bodyWrapper = $("#body-wrapper")
        const height =  $(window).height() - $("header").outerHeight() - ($(".info-wrapper").outerHeight() || 0)
        bodyWrapper.css("min-height", height)
    }

    customElements() {
        const self = this

        this.adjustCMSBodyHeight()

        $(window).on("resize", this.adjustCMSBodyHeight)

        $("[help-text]").each(function() {
            let txt = $(this).attr("help-text")

            const translatedHelpText = config.shared.helpTooltips[txt]
            if (translatedHelpText) {
                txt = translatedHelpText
            }

            $(this).css("position", "relative")
            $(this).append(`<div class="help-mark" hint-text="${txt}" hint-small="true">?</div>`)
            $(this).removeAttr("help-text")
        })

        $("ctm-select").each(function() {
            if ($(this).attr("init") === "true") {
                return
            }

            const parsedOptions = []
            const children = $(this).children()
            const value = $(this).prop("value")
            let text = ""
            for (let i = 0; i < children.length; i++) {
                const el = children[i]
                const elId = el.getAttribute("value")
                const elText = el.textContent
                parsedOptions.push({id: elId, value: elText, hidden: el.getAttribute("hidden")})

                if (value && value === elId) {
                    text = elText
                }
            }

            if (!parsedOptions[0]) {
                return
            }

            if (text === "") {
                text = parsedOptions[0].value
                $(this).prop("value", parsedOptions[0].id)
            }

            $(this).html(text)
            $(this).prop("options", parsedOptions)
            $(this).attr("init", "true")
        })

        $("ctm-select").off("click")
        $("ctm-select").on("click", function(event) {
            if ($(this).attr("disabled")) {
                return
            }

            const name = $(this).attr("name")
            const options = $(this).prop("options")
            let optionsHTML = ""

            if (!options || options.length <= 0) {
                notify.error("There is no more options in this list!")                
                return
            }
            for (let i = 0; i < options.length; i++) {
                if (options[i].hidden !== null) continue
                optionsHTML = optionsHTML + `<div class="ctm-option" refer="${name}" value="${options[i].id}">${options[i].value}</div>`
            }

            const attachedElement = window.storedCustomElements[name]

            if (attachedElement && attachedElement.length > 0) {
                attachedElement.remove()
                window.storedCustomElements[name] = undefined

                event.stopPropagation()
                return
            }

            let parent = $(".wrapper")
            let scrollParent = $(document)
            let zIndex = 1

            let wrappedInScroll;

            const position = $(this).offset()
            const parents = $(this).parents()

            for (let i = 0; i < parents.length; i++) {
                const className = parents[i].className
                if (className.search("panel__list") !== -1) {
                    zIndex = 8
                    if (!$(this).attr("simple-draw")) {
                        parent = $(".panel__list")
                    }

                    scrollParent = $(".list__wrapper")
                } else if (className.search("popup-element") !== -1) {
                    zIndex = 8
                } else if (className.search("collateral-section") !== -1) {
                    parent = $(".collateral-section")
                    scrollParent = $(".main-section__scrollable")
                    wrappedInScroll = true
                    break
                } else if (className.search("main-section") !== -1) {
                    
                }
            }
            
            let htmlContext = `
                <options>
                    ${optionsHTML}
                </options>
            `

            if (wrappedInScroll) {
                htmlContext = `
                    <div class="options-outer">
                        <options>
                            ${optionsHTML}
                        </options>
                    </div>
                `
            }

            parent.append(htmlContext)

            const optionsElement = $("options").last()

            let outerElement = optionsElement
            let outerOffset = {}

            if (wrappedInScroll) {
                outerElement = $(".options-outer").last()
                outerOffset = outerElement.offset() || {}
            }

            const [ox, oy] = self.getCurrentUIOffset(outerElement)

            position.left = position.left - ox - (outerOffset.left || 0)
            position.top = position.top + oy

            const headerOffset = self.getHeaderOffset(outerElement)
            const baseOffset = 21 + headerOffset
            let top = position.top + $(this).height() + baseOffset
            if (top + optionsElement.height() > $(window).height()) {
                top = top - $(this).outerHeight() - optionsElement.outerHeight()
            }

            top -= (outerOffset.top || 0)

            optionsElement.css({
                "left": position.left,
                "top": top,
                "width": $(this).outerWidth(true),
                "z-index": zIndex,
                "position": "absolute"
            })

            const optionElement = $(`.ctm-option[refer=${name}]`)
            optionElement.on("click", function(event) {
                const select = $(`ctm-select[name=${$(this).attr("refer")}]`)
                const text = $(this).html()

                if (select.html() == text) return

                select.html(text)
                select.prop("value", $(this).attr("value"))
                select.prop("text", text)
                select.trigger("change")

                const attachedElement = window.storedCustomElements[name]
                if (attachedElement && attachedElement.length > 0) {
                    attachedElement.remove()
                    window.storedCustomElements[name] = undefined
                }

                self.lastDatepicker = null
                event.stopPropagation()
            })

            optionElement.on("contextmenu", (e) => {
                event.stopPropagation()
            })

            window.storedCustomElements[name] = outerElement

            self.hideDatepicker()

            event.stopPropagation()
        })

        $(".checkbox[for]").off("click").on("click", function() {
            const checkbox = $("ctm-checkbox[id=" + $(this).attr("for") + "]")
            checkbox.trigger("click")
        })

        const switchHandlers = $("ctm-checkbox, .switcher")
        switchHandlers.off("click").on("click", function(e) {
            if ($(this).attr("disabled")) {
                return
            }

            const newState = !$(this).prop("checked")
            $(this).prop("checked", newState)

            if (newState) {
                $(this).addClass("checked")
            } else {
                $(this).removeClass("checked")
            }

            $(this).trigger("change")
            
            e.stopPropagation()
        })
    }

    updateHints() {
        const self = this
        const hints = $("[hint-text], [error-text]")

        hints.off("mouseenter")
        hints.off("mouseleave")
        hints.off("remove")

        hints.on("mouseenter", function() {
            if ($(window).outerWidth() < config.shared.mobileWidthBreakpoint) {
                return
            }

            let text = $(this).attr("hint-text") || ""
            let styleClass = ""

            if (text.trim() === "") return

            const errorText = $(this).attr("error-text")
            const hintDir = $(this).attr("hint-dir")
            if (errorText) {
                text = errorText
                styleClass = " hover-error"
            }

            if (text === "") {
                return
            }

            const previousHover = $(this).prop("attachedHint")
            
            if (previousHover) {
                if (errorText) {
                    return
                }

                previousHover.remove()
            }

            if (hintDir === "right") {
                styleClass += " hover-right"
            } else if (hintDir === "left") {
                styleClass += " hover-left"
            }

            if ($(this).attr("hint-small")) {
                styleClass += " hover-small"
            }
            
            self.createdHint = true
            
            $("body").append(`
                <div class="hover-message${styleClass}">${text}</div>
            `)

            const position = $(this).offset()
            const element = $(".hover-message").last()

            if (errorText) {
                element.attr("error", "true")
            }

            const elementOffset = Number($(this).attr("element-offset")) || 0

            const elementLeft = Math.max(position.left - element.outerWidth() / 2 + $(this).outerWidth() / 2 + elementOffset, 0)
            const windowWidth = $(window).width() - element.outerWidth() - 10

            const diff = elementLeft - windowWidth

            if (diff > 0) {
                element.css("--triangle-offset", `${diff}px`)
            }

            const baseOffset = 10

            let left = Math.min(elementLeft, windowWidth)
            let top = position.top - element.outerHeight() - baseOffset

            if (hintDir === "right") {
                left = position.left + elementOffset + $(this).outerWidth() + baseOffset
                top = position.top + $(this).outerHeight() / 2 - element.outerHeight() / 2
            } else if (hintDir === "left") {
                left = position.left - element.outerWidth() - baseOffset
                top = position.top + $(this).outerHeight() / 2 - element.outerHeight() / 2
            }

            element.css("left", left)
            element.css("top", top)

            $(this).prop("attachedHint", element)
        })

        const hintFade = function(removal) {
            const element = $(this).prop("attachedHint")

            if (!element) return
            if (element && element.attr("error") && !removal) return

            element.css("animation", "hover-disappear 0.25s ease")

            $(this).prop("attachedHint", null)

            timer.simple(0.2, () => {
                element.remove()
            })
        }

        hints.on("mouseleave", function(e, forced) {
            hintFade.call(this, forced)
        })
        
        hints.on("remove", function() {
            hintFade.call(this, true)
        })
    }

    addOption(element, option_id, name, hidden) {
        const options = element.prop("options") || []
        options.push({id: String(option_id), value: name, hidden: hidden || null})

        element.prop("options", options)

        const item = options[0]

        if (element.html().trim() === "" && item) {
            element.html(item.value)
            element.prop("value", item.id)
        }
    }

    removeOption(element, option_id) {
        const options = element.prop("options") || []
        const id = options.map(e => e.id).indexOf(option_id)
        if (id === -1) {
            return
        }

        options.splice(id, 1)

        const fOption = options[0]
        if (fOption) {
            element.html(fOption.value)
            element.prop("value", fOption.id)
        } else {
            element.html("")
            element.prop("value", null)
        }

        element.prop("options", options)
    }

    setButtonHandlers() {
        const self = this
        this.handlers = this.handlers || {}

        for (const [key, value] of Object.entries(this.handlers)) {
            const btn = $("[handler=" + key + "]")

            if (!btn[0]) continue

            btn.off("click.handler")

            btn.on("click.handler", function(e, ...args) {
                if ($(this).attr("disabled")) return
                value.handler.call(self, $(this), e, ...args)                
            })
        }
    }

    addButtonHandlers(handlers) {
        for (const [key, func] of Object.entries(handlers)) {
            this.handlers[key] = func
        }
    }

    listenButtonHandlers() {
        this.setButtonHandlers()
        new MutationObserver((e) => {
            this.setButtonHandlers()
        }).observe(document, {subtree: true, childList: true})
    }

    triggerHandler(handlerId, data = []) {
        $(`[handler=${handlerId}]`).trigger("click", data)
    }

    updateScrollContentHeight() {
        let height = $(window).height() - $(".program-wrapper").outerHeight() - $("header").outerHeight() - ($(".paginator-row").outerHeight() || 0) - ($(".account-header").outerHeight() || 0) - (($(".list__header").outerHeight() + 40) || 0)
        $(".block-scroll").css({
            "max-height": height,
        })
    }

    refreshImageElements() {
        $("img").each(function() {
            const src = $(this).attr("real-src")
            if (!src) {
                return
            }

            $(this).attr("src", src + "?timestamp=" + new Date().getTime())
        })
    }

    initializeColorpicker(...args) {
        const self = this

        for (let i = 0; i < args.length; i++) {
            const id = args[i]
            const colorpicker = document.querySelector(id)
            new JSColor(colorpicker, {
                format: "hex",
                palette: config.shared.colorPalette,
                onChange: function() {
                    self.onUpdate(self.updateID, this.targetElement)
                    self.storage[this.targetElement.getAttribute("name")] = this.targetElement.getAttribute("data-current-color")
                }
            })
    
            colorpicker.jscolor.fromString(this.storage[colorpicker.getAttribute("name")] || "#FFF")
        }
    }

    animateListItems() {
        $(".deals__deal").each(function() {
            $(this).css({
                "opacity": 0,
                "animation": `item-appear 0.25s ease ${$(this).index() * 0.025}s forwards`
            })
        })
    }
    
    setCustomParam(param, value) {
        this.params[param] = value

        hook.call("queryParamChanged", param, value)
    }

    getCustomParam(param) {
        return this.params[param]
    }

    getCustomParams() {
        const paramsData = this.params
        paramsData.showlist = true

        return getCommonParams(paramsData, true)
    }

    resetParams() {
        for (const [key, value] of Object.entries(this.params)) {
            hook.call("queryParamChanged", key, null)
            delete this.storage[key]
        }

        this.storage.search = ""

        this.params = {}

        this.applySortableClasses(true)
    }

    isSubscription() {
        return this.storage?.deal_type === "subscription"
    }

    // modules compability

    showWindow(title, secondary, permanent, onClose) {
        return menu.create(title, secondary, permanent, onClose)
    }

    closeWindow(suffix) {
        this.removeComboElements()

        return menu.close(suffix)
    }

    setWindowTitle(title, secondary) {
        return menu.setTitle(title, secondary)
    }

    setWindowHTML(html, secondary) {
        menu.setHTML(html, secondary)
        this.customElements()
    }

    setWindowRender(html, secondary) {
        menu.setRenderHTML(html, secondary)
        this.customElements()
    }

    setWindowClass(className, secondary) {
        return menu.setWindowClass(className, secondary)
    }

    setWindowPanelClass(className, secondary) {
        return menu.setPanelClass(className, secondary)
    }

    isWindowActive() {
        return menu.isActive()
    }
    
    load(element, small, alter, instant) {
        return net.load(element, small, alter, instant)
    }

    finishLoad() {
        return net.finishLoad()
    }

    createSelection(parent, element, options, pos, offsetx, offsety) {
        return selection.create(parent, element, options, pos, offsetx, offsety)
    }

    removeSelection(instant) {
        return selection.close(instant)
    }

    addInputError(element, text) {
        return cf.addInputError(element, text)
    }

    removeInputError(element) {
        return cf.removeInputError(element)
    }

    trackInput(input, condition, text, init) {
        return cf.trackInput(input, condition, text, init)
    }

    validateForm() {
        return cf.validateForm()
    }

    formHasError(warningMsg) {
        return cf.formHasError(warningMsg)
    }

    emailTracker(value, element) {
        return cf.emailTrackerFunc(value, element)
    }

    trackEmailAndPhones() {
        return cf.trackEmailAndPhones()
    }

    formatNumber(amount) {
        return cf.formatNumber(amount)
    }

    formatMoney(amount) {
        return cf.formatMoney(amount)
    }

    formatPercent(amount) {
        return cf.formatPercent(amount)
    }

    getCurrentUIOffset(element) {
        return cf.getCurrentUIOffset(element)
    }

    formatPhoneNumber(phone) {
        return cf.formatPhoneNumber(phone)
    }

    formatDate(dateValue) {
        return cf.formatDate(dateValue)
    }

    formatHumanDate(date, fullMonth) {
        return cf.formatHumanDate(date, fullMonth)
    }

    isAdmin() {
        return this.account.user_type === "type_root"
    }

    formatPassedTime(date) {
        const currentDate = new Date()

        let timeSpan = Math.floor((currentDate.getTime() - date.getTime()) / 1000)

        const minutesDiv = 60,
            hoursDiv = 3600,
            daysDiv = 86400

        let timeText = timeSpan < minutesDiv ? `${Math.floor(timeSpan)} seconds ago` : `${Math.floor(timeSpan / minutesDiv)} minutes ago`
        
        if (timeSpan > hoursDiv && timeSpan < daysDiv) {
            timeText = `${Math.floor(timeSpan / hoursDiv)} hours ago`
        } else if (timeSpan > daysDiv) {
            timeText = `${Math.floor(timeSpan / daysDiv)} days ago`
        }

        return [timeText, timeSpan]
    }
}

export default Structure