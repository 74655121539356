import component from "../modules/components.js"
import net from "../modules/network.js"
import notify from "../modules/notify.js"
import selection from "../modules/selection.js"
import config from "../../../lib/app-config.js"

const documentGroups = config.deal.stipulationGroups

function FileSection({id, name, isRequired, isLender}) {
    return /*html*/`
        <div class="files__item" category="${id}" ${isLender ? `lender="true"` : ""}>
            <div class="files-icon"></div>
            <div class="files-block">
                <span class="files-title">${name}</span>
            </div>
            ${isRequired ? `<div class="files-required">Required</div>` : ""}
            <div class="files-icon icon-click"></div>
        </div>
    `
}

const Stipulations = component.create({
    preRender: async function({ documents, dealId, userType, category }) {
        let counted = 0;
        for (const [key, value] of Object.entries(documents)) {
            if (!value.approved) continue
            counted++;
        }

        const length = documentGroups.length
        const percent = counted / length * 100

        let maxFiles = 0
        documentGroups.map(item => {
            if (item.isRequired) maxFiles++
        })


        let fileItems = ""
        for (let i = 0; i < length; i++) {
            const item = documentGroups[i]
            fileItems += FileSection(item)
        }

        return /*html*/`
            <div class="files-container">
                <div class="files__list">
                    <div class="files__requirements">
                        <span class=files__requirements-text>DOCUMENTS FOR FINANCING</span>
                        <div class="files__progressbar">
                            <div class="progressbar" style="width: ${percent}%"></div>
                        </div>
                        <div class="files__requirement-bottom">${counted} OUT OF ${maxFiles} APPROVED</div>
                    </div>
                    <div class="files__items">
                        ${fileItems}
                    </div>
                </div>
                <div class="files__input">
                    <div class="files__top">
                
                    </div>
                    <div class="files__bottom">
                        <input id="deal-file" type="file" multiple>
                        <div class="files-upload"></div>
                        <div class="files__input-text">Drop Your Files Or Click Here To Upload</div>
                    </div>
                </div>
            </div>
            
            <div class="files__viewer">
                <div class="viewer-header">
                    <div class="viewer-close"></div>
                </div>
                <div class="viewer-canvas"></div>
            </div>
        `
    },

    postRender: async function({ documents, dealId, userType, category }) {
        let selectedCategory = null
        let fileSelectedClass = "file-selected"
        let isLender = false
        let lastClickedSection = $(".files__file").first()

        const applyFileStyles = () => {
            $(".files__item").each(function() {    
                let category = $(this).attr("category")
                let textElem = $(this).children(".files-block").children(".files-text")
                let titleElem = $(this).children(".files-block").children(".files-title")
                textElem.html("Click On This Panel To Select Category")
                textElem.css("opacity", "0.5")
                titleElem.css("opacity", "0.5")
                
                const fileData = documents[documents.map(e => e.category).indexOf(category)]

                if (fileData) {
                    if (fileData.files.length > 0) {
                        titleElem.css("opacity", "1")
                    }

                    let status = $(this).children(".files-required")

                    if (userType === "type_dealer" && $(this).attr("lender")) {
                        status.html("For Lender")
                    }

                    const approveClass = "status-approved"
                    if (fileData.approved) {
                        status.html("Approved")
                        status.addClass(approveClass)
                    } else {
                        status.html("Required")
                        status.removeClass(approveClass)
                    }
                }
            })

        }

        $(".files__item").off().on("click", function() {
            lastClickedSection = $(this)
            isLender = $(this).attr("lender")

            $(".files__item").each(function() {
                $(this).removeClass(fileSelectedClass)
            })

            $(this).addClass(fileSelectedClass)

            selectedCategory = $(this).attr("category")

            const document = documents[documents.map(e => e.category).indexOf(selectedCategory)]

            const fileViewerClass = "files-view"
            const canvasAlignClass = "container__align-center"
            const fileViewer = $(".files__top")
            const viewCanvas =  $(".viewer-canvas")
            const fileOverlay = $(".files__viewer")

            fileViewer.html("")
            fileViewer.removeClass("files__border")

            if (!document) {
                return
            }

            const filesLength = document.files.length
            if (filesLength > 0) {
                fileViewer.addClass("files__border")
            }

            for (let i = 0; i < filesLength; i++) {
                const file = document.files[i]
                let subClass = ""
                if (file.approved) {
                    subClass = "file-approved"
                }

                if (file.denied) {
                    subClass = "file-denied"
                }
                const fileHTML = `
                    <div class="files__file ${subClass}" file-id="${i}">
                        <div class="file__name">${file.file_id}</div>
                        <div class="file__desc">${file.denied ? file.reason : ""}</div>
                    </div>
                `

                fileViewer.append(fileHTML)
            }

            const openPopup = () => {
                fileOverlay.addClass(fileViewerClass)

                $(".viewer-close").off().on("click", function() {
                    fileOverlay.removeClass(fileViewerClass)
                })
            }

            const canUseViewer = (extension) => {
                if (extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "txt" || extension === "tiff") {
                    return true
                }
                return false
            }

            $(".files__file").on("click", function(event) {
                const fileId = Number($(this).attr("file-id"))
                const file = document.files[fileId]

                let options = [
                    {
                        icon: "sidebar-print",
                        id: "view-file",
                        name: "View File"
                    },
                    {
                        icon: "sidebar-link",
                        id: "download-file",
                        name: "Download File"
                    },
                    {
                        icon: "sidebar-saveclose",
                        id: "change-file",
                        name: "Change File Name"
                    },
                    {
                        icon: "sidebar-remove",
                        id: "remove-file",
                        name: "Remove File"
                    },
                ]

                if (userType === "type_dealer") {
                    console.log(file)
                    if (file.approved) {
                        options = options.filter(item => {
                            return item.id === "view-file" || item.id === "download-file"
                        })
                    }
                } else {
                    options.push({
                        icon: "sidebar-verify",
                        id: "approve-file",
                        name: "Approve File"
                    })
                    options.push({
                        icon: "sidebar-return",
                        id: "deny-file",
                        name: "Deny File"
                    })
                }

                selection.create($(".files__input"), $(this), options, true)

                const link = "/deals/" + dealId + "/upload/" + selectedCategory + "/" + file.file_id
                const linkForAction = "/deals/" + dealId + "/upload/" + selectedCategory + "/" + fileId
                const spl = file.file_id.split(".")
                const extension = spl[spl.length - 1]

                $(".files-link").remove()
                fileViewer.append(`<a href="${link + "?download=true"}" target="_blank" class="files-link" id="download-href">`)
                fileViewer.append(`<a href="${link}" target="_blank" class="files-link" id="view-href">`)

                $("#view-file").on("click", async function() {
                    if (!canUseViewer(extension)) {
                        $("#view-href")[0].click()
                        return
                    }

                    openPopup()

                    if (extension === "txt") {
                        let res = await net.request("GET", link)
                        res = res.replaceAll("\n", "<br>")
                        viewCanvas.html(res)
                        viewCanvas.removeClass(canvasAlignClass)
                    } else {
                        viewCanvas.addClass(canvasAlignClass)
                        viewCanvas.html(`<img style="height: 100%" src="${link}" alt="image">`)
                    }
                })

                $("#download-file").on("click", function() {
                    $("#download-href")[0].click()
                })

                const addAction = (id, action, responseText, input) => {
                    $("#" + id).on("click", function() {
                        const makeRequest = async () => {
                            const value = $("#filetext-input").val()
                            let url = linkForAction + "?action=" + action
                            if (value) {
                                url = url + "&value=" + value
                            }

                            if (isLender && userType === "type_dealer") {
                                notify.error("This file category is restricted for dealers!", 5)
                                return
                            }

                            const res = await net.request("POST", url)
                            net.finishLoad()
                            if (res.error) {
                                notify.error(res.error, 5)
                            } else {
                                notify.success(responseText, 5)
                                documents = res.payload
                                lastClickedSection.trigger("click")
                                applyFileStyles()
                            }
                        }

                        if (input) {
                            viewCanvas.addClass("canvas-flex")
                            viewCanvas.html(`
                                <div class="canvas-container">
                                    <span class="input__text">${input.title}</span>
                                    <input value="" type="text" class="input__entry sz-xl" id="filetext-input" />
                                    <div class="btn" id="submit-input">${input.text}</div>
                                </div>
                            `)

                            openPopup()

                            $("#submit-input").off().on("click", function() {
                                makeRequest()
                                $(".viewer-close").trigger("click")
                            })
                        } else {
                            makeRequest()
                        }
                    })
                }


                addAction("remove-file", "remove", "You have successfully removed this file!")
                addAction("change-file", "change", "You have successfully change this file name!", {title: "New File Name", text: "Submit New Name"})
                addAction("approve-file", "approve", "You have successfully approved this file!")
                addAction("deny-file", "deny", "You have successfully denied this file!", {title: "Deny Reason", text: "Submit Reason"})

                event.stopPropagation()
            })
        })
        
        if (category) {
            $(".files__item[category=" + category + "]").trigger("click")
        } else {
            $(".files__item").first().trigger("click")
        }

        applyFileStyles()

        $("#deal-file").on("change", async function() {
            if (isLender && userType === "type_dealer") {
                notify.error("This file category is restricted for dealers!", 5)
                return
            }

            net.load(".wrapper")

            const input = $(this)
            const formData = new FormData()
            for (let i = 0; i < this.files.length; i++) {
                formData.append("file", this.files[i])
            }

            const res = await net.formData("POST", `/deals/${dealId}/upload?category=${selectedCategory}`, formData)
            net.finishLoad()

            input.val("")

            switch (res.code) {
                case 200: {
                    notify.success("You have successfully uploaded file!", 5)
                    documents = res.payload
                    lastClickedSection.trigger("click")
                    applyFileStyles()
                    break
                }
                
                case 405: {
                    notify.error("You can attach files only when dealer will accept your offer!")
                    break
                }

                case 407: {
                    notify.error(`You can attach no more than ${config.deal.maxFilesPerCategory} files per category!`)
                    break
                }

                case 408: {
                    notify.error("Files with this extension cannot be uploaded!")
                    break
                }

                case 502: {
                    notify.error("Unexpected error! Please refresh your page and try again!")
                    break
                }

                case 503: {
                    notify.error("File size cannot be larger than 4 MB!")
                    break
                }
            }
        })
    }
})

export default Stipulations