import Structure from "./structure.js";

import { getCommonParams } from "./modules/common-func.js";
import timer from "./modules/timer.js";
import notify from "./modules/notify.js";
import component from "./modules/components.js";
import config from "../../lib/app-config.js";

new Structure({
    authFree: true,

    handlers: {
        requestConfirmLink: {
            handler: async function() {
                const payload = this.account
                this.load(".notactive-banner", true)
                const res = await this.request("POST", "/confirm?user_id=" + payload.user_id)
                this.finishLoad()
                if (res.result) {
                    notify.success("Link has been successfully sent to your email address!", 5)
                } else {
                    notify.error("Previous link is still not expired, we can't send another one!", 5) 
                }
            }
        },

        closeBanner: {
            handler: async function() {
                $(".banner").remove()
            }
        },

        showFilters: {
            handler: async function() {
                this.showFilters()
            }
        },

        applyFilters: {
            handler: function() {
                const url = window.location.pathname + getCommonParams(this.storage)
            
                window.location.replace(url)
            }
        },

        resetFilters: {
            handler: function() {
                window.location.replace(window.location.pathname)
            }
        },

        clearAllNotifications: {
            handler: async function() {
                const res = await this.request("POST", "/account/notifications")

                notify.success("You have successfully cleared all notifications!")

                this.loadNotifications()
            }
        },

        clearNotification: {
            handler: async function(element) {
                const id = element.attr("item-id")
                $(".notification[item-id=" + id + "]").remove()

                if ($(".notification").length === 0) {
                    this.loadNotifications()
                }
 
                const res = await this.request("PUT", `/account/notifications/${id}`)
            }
        }
    },

    async checkEmailConfirmed() {
        const payload = this.account

        if (payload.email_confirmed === false) {
            const html = await this.template("ConfirmationWarning")
            await component.prependHTML($(".wrapper"), html)
        }
    },

    async loadSupportElement() {
        const duration = 0.25
        const wrapper = typeof $(".wrapper")[0] === "undefined" ? $("body") : $(".wrapper")
        const SupportTemplate = await this.template("Support")
        wrapper.append(SupportTemplate)
    
        const supportMain = $("#support-ticket")
        const supportImage = $(".support__wrapper-image")
        const supportInputs = $(".support-item")
        const activeClass = "support-active"
    
        supportMain.on("click", function(event) {
            $(this).addClass(activeClass)
            $(this).css({
                "animation": "support_grow " + duration + "s ease forwards",
                "padding": "10px"
            })
    
            supportImage.animate({top: "7%"}, duration * 1000)
            timer.simple(duration / 2, () => {
                supportInputs.css("display", "flex")
                supportInputs.animate({
                    opacity: 1
                }, 250)
            })
    
            event.stopPropagation()
        })
    
        const handleClose = () => {
            if (!supportMain.hasClass(activeClass)) {
                return
            }
    
            supportMain.css({
                "animation": "support_small " + duration + "s ease forwards",
                "padding": ""
            })
    
            supportImage.animate({top: "50%"}, duration * 1000)
            supportInputs.animate({opacity: 0}, 100)
            supportInputs.css("display", "none")
    
            timer.simple(duration, () => {
                supportMain.removeClass(activeClass)
            })
        }
    
        let file;
    
        const fileInput = $("#support-file")
        const fileInputWrapper = $(".input__file")
        
        supportMain.on("paste", function (event) {
            fileInput[0].files = event.originalEvent.clipboardData.files
            fileInput.trigger("change")
        })
    
        fileInput.on("change", function() {
            const loadedFile = this.files[0]
            if (!loadedFile) return
            fileInputWrapper.html(loadedFile.name)
            file = loadedFile
        })
    
        fileInputWrapper.on("click", function() {
            fileInput.trigger("click")
        })
        
        const subjectMinLength = config.tickets.subjectMinLength
        const subjectMaxLength = config.tickets.subjectMaxLength
        const textMinLength = config.tickets.textMinLength
        const textMaxLength = config.tickets.textMaxLength
    
        const ticketThemeInput = $("#support_ticket_theme")
        const ticketTextInput = $("#support_ticket_text")
        const counterElement = $("#support-counter")
    
        const updateTextCounter = () => {
            const textLength = ticketTextInput.val().length
            counterElement.html(textLength + "/" + textMaxLength + " symbols")
            if (textLength > textMaxLength) {
                counterElement.addClass("text-error")
            } else {
                counterElement.removeClass("text-error")
            }
        }
    
        updateTextCounter()
    
        ticketTextInput.attr("maxsymbols", textMaxLength)
        ticketTextInput.on("input", function() {
            updateTextCounter()
        })
    
        $("#submit-ticket").on("click", async () => {
            const subjectLength = ticketThemeInput.val().length
            const textLength = ticketTextInput.val().length
    
            if (subjectLength < subjectMinLength) {
                notify.error("Subject of Issue is too short!", 5)
                return
            }
            if (subjectLength > subjectMaxLength) {
                notify.error("Your subject is too large!", 5)
                return
            }
            if (textLength < textMinLength) {
                notify.error("Please use more words to describe your issue!", 5)
                return
            }
            if (textLength > textMaxLength) {
                notify.error("Your description is too large!", 5)
                return
            }
    
            const formData = new FormData()
            formData.append("file", file)
            formData.append("subject", ticketThemeInput.val())
            formData.append("text", ticketTextInput.val())
    
            this.load(".support-icon")

            const res = await this.formData("POST", "/issue", formData)
            res.receive(() => {
                notify.success("Your ticket was successfully submitted!<br>Thank you for your time and effort!", 5)
                this.storage["support_ticket_theme"] = ""
                this.storage["support_ticket_text"] = ""

                ticketThemeInput.val("")
                ticketTextInput.val("")
                fileInput.val("")
                fileInputWrapper.html("")
                handleClose()
                updateTextCounter()
                file = null;
            }, {
                405: "Files with this extension cannot be sent!",
                406: "File size is larger than 8 MB!"
            })

            this.finishLoad()
        })
    
        $(window).on("click", function() {
            const classList = window.clickStartedElement?.classList
            if (classList && Array.from(classList).join(" ").search("input") !== -1) {
                return
            }
            
            handleClose()
        })
    },

    hookHeader() {
        const headerElement = $(".header")
        const accountOptions = $(".header__extend")
        const profileElement = $(".header__profile")
        const optionsList = $(".extend__option")
        
        const activeClass = "extend-active"
        
        let blocked = false
    
        const close = () => {
            let iterator = optionsList.length
            
            profileElement.removeClass("arrow-rotate")
    
            accountOptions.removeClass(activeClass)
            optionsList.each(function() {
                $(this).css({
                    "animation": "extend_disappear 0.1s ease forwards",
                    "animation-delay": `${0.1 * iterator}s`
                })
                iterator = iterator - 1
            })
    
            timer.create("headerEvent", 0.4, 1, () => {
                blocked = false
                accountOptions.css("display", "none")
            })
        }
    
        accountOptions.on("click", function(event) {
            event.stopPropagation()
        })
    
        $(window).on("click", function() {
            if (blocked) {
                return;
            }
    
            close()
        })
    
        profileElement.on("click", (event) => {
            if (blocked) {
                return;
            }

            $("#notifications").removeClass("notifications-show")
    
            blocked = true
    
            profileElement.toggleClass("arrow-rotate")
            
            if (accountOptions.css("display") == "none") {
                timer.remove("headerEvent")
    
                accountOptions.css({
                    "display": "block",
                    "left": $(window).width() - accountOptions.width() + 10,
                    "top": headerElement.height()
                })

                accountOptions.addClass(activeClass)

                let iterator = 0
                optionsList.each(function() {
                    $(this).css({
                        "transform": "translateX(100%)",
                        "animation": "extend_appear 0.1s ease forwards",
                        "animation-delay": `${0.1 * iterator}s`
                    })
                    iterator++;
                })
    
                timer.simple(0.4, () => {
                    blocked = false
                    optionsList.each(function() {
                        $(this).css("transform", "translateX(0%)")
                    })
                })
            } else {
                close()
            }
    
            event.stopPropagation()
        })
    
        $("#btn-logout").on("click", () => {
            $.ajax({
                type: "POST",
                url: "/auth/logout",
                success: () => {
                    window.location.replace("/login")
                }
            })
        })
    },

    parseURLParams() {
        const location = window.location.pathname
        const params = new URLSearchParams(window.location.search)

        this.storage = {}

        const maxItemsBreakpoints = config.pagination.segments
        const MIN_ITEMS = maxItemsBreakpoints[0],
            MAX_ITEMS   = maxItemsBreakpoints[maxItemsBreakpoints.length - 1]

        for (let [key, value] of params.entries()) {
            if (key === "max_items") {
                if (maxItemsBreakpoints.indexOf(Number(value)) === -1) {
                    this.addOption($("#max_items"), value, value)
                }

                value = Math.max(Math.min(value, MAX_ITEMS), MIN_ITEMS)
            }

            this.storage[key] = value
        }

        const configuratePaths = {
            "/customers": true,
            "/deals": true,
            "/vehicles": true
        }

        if (!configuratePaths[location]) return

        const queryMemory = JSON.parse(localStorage.getItem("queryMemory")) || {}
        queryMemory[location] = this.storage

        localStorage.setItem("queryMemory", JSON.stringify(queryMemory))
    },

    onComboChanged(element, idName, value) {
        if (idName !== "max_items") return
        const url = window.location.pathname + getCommonParams({
            max_items: value
        })

        window.location.replace(url)
    },

    hookDroppables() {
        const droppableClass = "droppable-hover"
        $(".nav__link[for], .nav__list-droppable").on("mouseenter", function() {
            const id = $(this).attr("for") || $(this).attr("id")
            const droppable = $("#" + id)
            droppable.addClass(droppableClass)
            timer.remove(id)
        })

        $(".nav__link[for], .nav__list-droppable").on("mouseleave", function() {
            const id = $(this).attr("for") || $(this).attr("id")
            const droppable = $("#" + id)
            timer.create(id, 0.1, 1, () => {
                droppable.removeClass(droppableClass)
            })
        })
    },

    notificationsHandler() {
        const bellBtn = $("#btn-bell")
        const notifications = $("#notifications")
        const list = notifications.children(".header__notifications-list")

        const activeClass = "notifications-show"

        $(window).on("click", () => {
            notifications.removeClass(activeClass)
        })

        const bellIcon = $("#bell-icon")

        const newNotificationsClass = "new-notifications"
        const originalSVG = bellIcon.html()

        if (this.account.isNewNotifications) {
            bellIcon.addClass(newNotificationsClass)
            bellIcon.html(`
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6zm5.778-11.679c.18.721.05 1.446-.304 2.035l.97.584c.504-.838.688-1.869.433-2.892-.255-1.024-.9-1.848-1.739-2.351l-.582.97c.589.355 1.043.934 1.222 1.654zm.396-4.346l-.597.995c1.023.616 1.812 1.623 2.125 2.874.311 1.251.085 2.51-.53 3.534l.994.598c.536-.892.835-1.926.835-3-.001-1.98-1.01-3.909-2.827-5.001zm-16.73 2.692l-.582-.97c-.839.504-1.484 1.327-1.739 2.351-.255 1.023-.071 2.053.433 2.892l.97-.584c-.354-.588-.484-1.314-.304-2.035.179-.72.633-1.299 1.222-1.654zm-4.444 2.308c0 1.074.299 2.108.835 3l.994-.598c-.615-1.024-.841-2.283-.53-3.534.312-1.251 1.101-2.258 2.124-2.873l-.597-.995c-1.817 1.092-2.826 3.021-2.826 5z"/></svg>    
            `)
        }

        bellBtn.on("click", async (e) => {
            e.stopPropagation()

            notifications.toggleClass(activeClass)
            const posLeft = $(window).outerWidth() < 800 ? $(window).outerWidth() - notifications.outerWidth() : bellBtn.offset().left - notifications.outerWidth()
            notifications.css("left", posLeft)
            
            if (!notifications.hasClass(activeClass)) {
                return
            }

            bellIcon.html(originalSVG)
            bellIcon.removeClass(newNotificationsClass)

            list.html("")

            this.load(notifications)
            const res = await this.request("GET", "/account/notifications")
            this.finishLoad()

            const payload = res.payload.reverse()

            this.loadNotifications(payload)
        })

        notifications.on("click", (e) => {
            e.stopPropagation()
        })
    },

    async loadNotifications(payload = []) {
        const notifications = $("#notifications")
        const list = notifications.children(".header__notifications-list")

        if (payload.length === 0) {
            list.html("<div class='header__notifications-empty'>No new notifications!</div>")
            list.css("height", "100%")
        } else {
            list.css("height", "")
        }

        for (let i = 0; i < payload.length; i++) {
            const item = payload[i]

            const [timeText] = this.formatPassedTime(new Date(item.date))

            list.append(await this.template("Notification", {
                id: item.notifyId,
                isOdd: i % 2 !== 0 ? "notification-odd" : "",
                time: timeText,
                text: item.text,
                type: item.notifyType
            }))
        }
    },

    async processAccountData() {
        const res = await this.retrieveAccountInfo()
        const payload = this.account || {}
        const context = res.context || {}
    
        if (!payload) {
            this.finishLoad()
            return
        }

        const companyNameLabel = $("#company-name"),
            accountNameLabel = $("#account-name");

        if (!payload.user_name) {
            $("#btn-bell").remove()

            const wrap = $(".header__account")
            wrap.css({
                "align-items": "center",
                "justify-content": "center",
                "margin-right": "20px"
            })
            wrap.html(/*html*/`
                <a class="btn btn-black" href="/login?fallbackUrl=/docs">Log in</a>   
            `)
        } else {
            accountNameLabel.html(payload.user_name || "")
            companyNameLabel.html(context.companyName || "")
        }
    },

    async configurateHTML() {
        const self = this
        const urlParams = new URLSearchParams(window.location.search)
        const body = $("body")
        const payload = this.account

        $(".deal__status").each(function() {
            self.updateStatusMark($(this), $(this).attr("status"))
        })

        $(".nav__item").each(function() {
            const link = $(this).children(".nav__link")
            const href = link.attr("href")

            if (href !== "/customers" && payload.user_type === "type_plaid") {
                $(this).remove()
                return
            }
    
            if (href !== "/schedule" && payload.user_type === "type_schedule") {
                $(this).remove()
                return
            }
    
            $(this).css("opacity", 1)
    
            if (window.location.pathname == href) {
                link.addClass("link-active");
            };
        });
    
        $(".header__name[value-id=deal_status_value]").each(function() {
            const statusMap = $(this).attr("category") ? self.dealStatus[$(this).attr("category")] : self.dealStatus.base

            let name = statusMap[ $(this).html() ]
            if (typeof name === "object") {
                name = name.value
            }

            $(this).html(name)
        })

        // Only create input listeners when we are having a table
        if ($(".header__name")[0]) {
            $("#search-side").prepend(await this.template("HeaderNav"))
            this.compile({
                hookInputs: true,
                writeValues: true
            })
        }

        if (window.location.pathname !== "/deals") {
            $(".filter-btn").remove()
        }

        $(".wrapper").addClass("wrapper-visible")
        $("#list-search").val(urlParams.get("search") || "")

        this.finishLoad()

        if (!localStorage.getItem("cookie-consent")) {
            body.append(await this.template("Cookies"))
    
            $(".cookies__button").on("click", () => {
                $(".cookies").remove()
                localStorage.setItem("cookie-consent", "1")
            })
        }
    },

    async initialize() {
        const currentPath = window.location.pathname

        $(window).on("mousedown", function(event) {
            window.clickStartedElement = event.target
        })
    
        $(window).on("mouseup", function(event) {
            window.clickEndedElement = event.target
        })

        window.mouse = {}
        $(document).on("mousemove", function(event) {
            window.mouse.x = event.pageX;
            window.mouse.y = event.pageY;
        });
        
        $(document).on("keydown", (e) => {
            if (e.key !== "Escape") return

            if ($(".popup-window-2").hasClass("window-active")) {
                this.closeWindow("-2")                
            } else {
                this.closeWindow()
            }
        })
        
        // datepicker fix
        $.browser = {};
        (() => {
            $.browser.msie = false;
            $.browser.version = 0;
            if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
                $.browser.msie = true;
                $.browser.version = RegExp.$1;
            };
        })()

        this.load()

        const excludePaths = {
            "/login": true
        }

        if (excludePaths[currentPath]) {
            this.finishLoad()
            return
        }

        this.parseURLParams()

        await this.processAccountData()
        await this.configurateHTML()

        this.compile({
            animateListItems:           true,
            checkEmailConfirmed:        true,
            loadSupportElement:         true,
            addWindowListener:          true,
            hookSearchInput:            true,
            hookHeader:                 true,
            hookSortableColumns:        true,
            customElements:             true,
            updateScrollContentHeight:  true,
            hookDroppables:             true,
            listenButtonHandlers:       true,
            notificationsHandler:       true
        })
    }
})