const config = {}

config.pagination = {}
config.pagination.segments = [15, 25, 50]
config.pagination.maxPages = 6

config.mail = {}
config.mail.address = "support@dealcenter.app"
config.mail.themePrefix = ""

config.account = {}
config.account.maxPrograms = 20
config.account.maxDealers = 20
config.account.maxCharts = 15
config.account.maxCustomFields = 15
config.account.permissions = {
    car: "Auto Loan",
    personal: "Personal Loan",
    title: "Title Loan",
    business: "Business Loan",
    flooring: "Flooring Loan"
}

config.deal = {}
config.deal.logsPerStack = 10
config.deal.maxFilesPerCategory = 15
config.deal.statuses = {
    base: {
        deal_status_init: "APPLICATION",
        deal_status_application: "SUBMITTED",
        deal_status_denied: "DENIED",
        deal_status_preapproved: {value: "PRE-APPROVED", hidden: true},
        deal_status_accepted: "APPROVED",
        deal_status_countered: "COUNTERED",
        deal_status_infunding: "IN FUNDING",
        deal_status_funded: "FUNDED",
        deal_status_dumped: {value: "CANCELLED", hidden: true},
        deal_status_removed: {value: "REMOVED", hidden: true},
        deal_status_contract_requested: {value: "CONTRACT REQUESTED", hidden: true},
    },

    subscription: {
        deal_status_init: "INACTIVE",
        deal_status_application: "ACTIVE",
        deal_status_finished: "CLOSED - PAID",
        deal_status_dumped: "CLOSED - CHARGED OFF",
        deal_status_removed: "REMOVED",
    }
}
config.deal.whitelistedFields = {
    deal_date: true
}
config.deal.paymentFrequency = {
    months: "Monthly",
    semi_months: "Semi-Monthly",
    bi_weeks: "Bi-Weekly",
    weeks: "Weekly",
    days: "Daily",
    one_pay: "One Time",
}
config.deal.bureaus = {
    "XPN": "xpn_url",
    "TU": "tu_url"
}
config.deal.vehicleFields = {
    vehicle_year: Number,
    vehicle_mark: String,
    vehicle_model: String,
    vehicle_vin: String,
    vehicle_mileage: Number
}
config.deal.stipulationGroups = [
    {
        id: "dl_id",
        name: "DL/ID",
        isRequired: true,
        isLender: false
    },
    {
        id: "proof_of_residence",
        name: "Proof Of Residence",
        isRequired: true,
        isLender: false
    },
    {
        id: "proof_of_income",
        name: "Proof Of Income",
        isRequired: true,
        isLender: false
    },
    {
        id: "credit_report",
        name: "Credit Report",
        isRequired: true,
        isLender: false
    },
    {
        id: "esigned_docs",
        name: "E-Signed Docs",
        isRequired: true,
        isLender: false
    },
    {
        id: "bill_of_sale",
        name: "Bill Of Sale",
        isRequired: true,
        isLender: false
    },
    {
        id: "insurance",
        name: "Insurance Binder",
        isRequired: true,
        isLender: false
    },
    {
        id: "gps_sticker",
        name: "GPS Sticker",
        isRequired: false,
        isLender: false
    },
    {
        id: "other",
        name: "Other",
        isRequired: false,
        isLender: false
    },
    {
        id: "funding_notice",
        name: "Funding Notice",
        isRequired: true,
        isLender: true
    },
    {
        id: "approval",
        name: "Approval",
        isRequired: true,
        isLender: true
    },
    {
        id: "registration_or_title_application",
        name: "Registration/Title Application",
        isRequired: true,
        isLender: false
    }
]
config.deal.warningStoredDetections = [
    {
        text: "Vehicle Book Values does not match current month",
        checker: function() {
            if ((this.storage.vehicle_id || -1) === -1) {
                return false
            }

            const date = new Date()
            const jdPowerDate = date.getFullYear() + "-" + this.formatDate((date.getMonth() + 1)) + "-01"
            return (jdPowerDate !== this.storage.jdpower_period)
        }
    },
    {
        text: "Deal Date does not match current date",
        checker: function() {
            const date = new Date()
            const dealDate = new Date(this.storage.deal_date)
            return dealDate.getDate() !== date.getDate() || dealDate.getMonth() !== date.getMonth() || dealDate.getFullYear() !== date.getFullYear()
        }
    },
    {
        text: "Customer has connected bank account",
        dealer: true,
        status: "warning-good",
        checker: function() {
            return this.storage.plaid_connected === true
        }
    },
    {
        textFromFieldId: "nonFundableText",
        dealer: true,
        dealerOnly: true,
        checker: function() {
            return (this.storage.nonFundableText || "") !== ""
        }
    }
]
config.deal.fees = [
    "license_fee",
    "reg_fee",
    "filing_fee",
    "other_fees",
    "tire_fee",
    "smog_fee",
    "smog_cert_fee",
    "doc_fee",
    "contract_cancel_fee",
    "lender_process_fee"
]
config.deal.fields = {
    "amount_financed": {name: "Amount Financed"},
    "finance_charge": {name: "Finance Charge"},
    "net_check": {name: "Net Check"},
    "discount": {name: "Discount"},
    "discount_percentage": {name: "Discount Percentage", format: "%"},
    "closing_fee": {name: "Closing Fee"},
    "closing_fee_percentage": {name: "Closing Fee Percentage", format: "%"},

    "fees": {name: "Fees"},
    "doc_fee": {name: "Doc Fee"},
    "license_fee": {name: "License Fee"},
    "filing_fee": {name: "Filing Fee"},
    "reg_fee": {name: "Reg/Trans Fee"},
    "tire_fee": {name: "Tire Fee"},
    "other_fees": {name: "Other Fees"},
    "smog_cert_fee": {name: "Smog Cert Fee"},
    "smog_fee": {name: "Smog Fee"},
    "contract_cancel_fee": {name: "Contract Cancellation Fee"},
    "lender_process_fee": {name: "Lender Processing Fee"},

    "vehicle_price": {name: "Vehicle Price"},
    "surface_cost": {name: "Surface Cost"},
    "surface_protection": {name: "Surface Protection"},
    "lender_fee": {name: "Lender Fee"},
    "price": {name: "Total Vehicle Price"},

    "down_payment": {name: "Down Payment"},
    "first_payment": {name: "First Payment", format: ""},
    "interest_rate": {name: "APR", format: "%"},
    "monthly_payment": {name: "Payment"},

    "ltv": {name: "LTV", format: "%"},
    "ltv_lender": {name: "LTV (Lender)", format: "%"},
    "pti": {name: "PTI", format: "%"},
    "pti_ocrolus": {name: "PTI (DealCenter)", format: "%"},

    "sales_tax_percent": {name: "Sales Tax Rate", format: "%"},

    "term": {name: "Term", format: ""},
    "term_count": {name: "Term Count", isHidden: true},

    "trade_acv_1": {name: "Trade ACV #1"},
    "trade_acv_2": {name: "Trade ACV #2"},
    "trade_allowance_1": {name: "Trade Allowance #1"},
    "trade_allowance_2": {name: "Trade Allowance #2"},
    "trade_payoff_1": {name: "Trade Payoff #1"},
    "trade_payoff_2": {name: "Trade Payoff #2"},
    
    "apy": {name: "APY", format: "%"},
    "irr": {name: "IRR", format: "%"},

    "credit_score": {name: "Credit Score", format: ""},

    "vehicle_mileage": {name: "Vehicle Mileage", format: ""},
}

config.deal.program = {}
config.deal.program.replacements = {
    "pti_ocrolus": ({ customer }) => {
        return customer.plaid_connected ? "pti_ocrolus" : "pti"
    }
}
config.deal.program.fields = {
    max_pti: {
        field: "pti_ocrolus",
        name: "Max. PTI",
        format: "%",
        preapprove: true,
    },
    max_ltv: {
        field: "ltv",
        name: "Max. LTV",
        format: "%",
        preapprove: true
    },
    min_mileage: {
        vehicle: true,
        field: "vehicle_mileage",
        name: "Min. Mileage",
        min : true,
        preapprove: true,
        format: ""
    },
    max_mileage: {
        vehicle: true,
        field: "vehicle_mileage",
        name: "Max. Mileage",
        preapprove: true,
        format: ""
    },
    min_term: {
        field: "term",
        name: "Min. Term",
        min: true,
        preapprove: true,
        format: ""
    },
    max_term: {
        field: "term",
        name: "Max. Term",
        preapprove: true,
        format: ""
    },
    min_score: {
        field: "credit_score",
        name: "Min. Credit Score",
        min: true,
        preapprove: true,
        format: ""
    },
    max_score: {
        field: "credit_score",
        name: "Max. Credit Score",
        preapprove: true,
        format: ""
    },
    max_age: {
        field: "vehicle_year",
        name: "Max. Vehicle Age",
        format: "",
        filter: (value, set) => {
            const year = new Date().getFullYear() - set
            if (value < year) {
                return true
            }

            return false
        },
        preapprove: true
    },
    standart_ltv: {
        field: "ltv",
        name: "Autostructure LTV",
        calc: {
            "vehicle_price": (deal, vehicle, customer, programValues) => {
                if (!vehicle) {
                    return 0                    
                }

                const tradeClean = vehicle.jd_total_tradeclean
                const value = programValues.standart_ltv
                const lenderFee = deal.lender_fee || 0

                if (!tradeClean) {
                    return 0                    
                }

                let ltv = 0
                let price = tradeClean
                let down = deal.down_payment
                let amountFinanced = 0;

                const step = price * 0.002
                const startTime = performance.now()

                while (Math.abs(ltv - value) > 0.3) {
                    if (ltv > value) {
                        price -= step
                    } else {
                        price += step
                    }

                    if (programValues["standart_down"]) {
                        down = Math.round(price * programValues["standart_down"] / 100)
                    }

                    let tax = 0;
                    if (deal.sales_tax_percent > 0) {
                        tax = (price + lenderFee + deal.smog_fee + deal.doc_fee + deal.accessories_total) * (deal.sales_tax_percent / 100)
                    }

                    const totalPrice = (price + (deal.surface_protection || 0)) + (deal.accessories_total || 0) + (deal.vcs_products || 0) + (deal.insurance || 0)
                    amountFinanced = totalPrice + deal.fees - down + tax + lenderFee
    
                    ltv = (amountFinanced / tradeClean) * 100

                    if (ltv < 0) {
                        price = 0
                        down = 0
                        amountFinanced = 0
                        break
                    }
                }

                const endTime = performance.now()

                return {vehicle_price: price, down_payment: down, amount_financed: amountFinanced}
            }
        },
        format: "%"
    },
    standart_down: {
        field: "down_payment",
        name: "Autostructure Down Payment",
        format: "%",
        calc: {}
    },
    standart_term: {
        field: "term",
        name: "Autostructure Term",
        format: "",
        plain: true,
    },
    standart_interest: {
        field: "interest_rate",
        name: "Autostructure Interest Rate",
        plain: true,
        format: "%"
    },
    standart_discount: {
        field: "discount_percentage",
        name: "Autostructure Discount",
        plain: true,
        format: "%"
    },
    standart_gap_amount: {
        field: "gap_amount",
        name: "Autostructure Gap Amount",
        format: "$",
        plain: true
    },
    standart_gap_cost: {
        field: "gap_cost",
        name: "Autostructure Gap Cost",
        format: "$",
        plain: true
    },
    standart_nonfundable_text: {
        field: "nonFundableText",
        isText: true,
        name: "Funding Warning",
        conditionOnly: true,
        plain: true
    }
}

config.customer = {}
config.customer.fields = {
    "customer_name": {name: "Customer Name"},
    "customer_cellphone": {name: "Customer Cell Phone", format: "+1"},
    "customer_workphone": {name: "Customer Work Phone", format: "+1"},
    "customer_email": {name: "Customer Email"},
    "customer_businessrole": {name: "Customer Business Role"},
    "customer_businessname": {name: "Customer Business Name"},
    "customer_businessphone": {name: "Customer Business Phone"},
    "customer_businessemail": {name: "Customer Email"},
    "customer_tradename": {name: "Customer Trade Name"},
    "customer_crosscollateral": {name: "Customer Cross-Collateral"},
    "customer_entitytype": {name: "Customer Type Of Entity"},
    "customer_business_address": {name: "Customer Business Address"},
    "customer_mailing_address": {name: "Customer Mailing Address"},
    "customer_ssn": {name: "Customer SSN", format: ""},
    "customer_idtype": {name: "Customer ID Type"},
    "customer_idstate": {name: "Customer ID State"},
    "customer_idnumber": {name: "Customer ID Number"},
    "customer_current_address": {name: "Customer Current Address"},
    "customer_current_address_type": {name: "Customer Current Address Type"},
    "customer_current_address_rent": {name: "Customer Current Address Rent / Mort. Amt"},
    "customer_previous_address": {name: "Customer Previous Address"},
    "customer_previous_address_type": {name: "Customer Previous Address Type"},
    "customer_previous_address_rent": {name: "Customer Previous Address Rent / Mort. Amt"},
    "customer_account_address": {name: "Customer Billing Address"},
    "customer_account_type": {name: "Customer Account Type"},
    "customer_account_name": {name: "Customer Name On Account"},
    "customer_account_number": {name: "Customer Bank Account Number", format: ""},
    "customer_account_routingnumber": {name: "Customer Bank Routing Number", format: ""},
    "customer_account_bankname": {name: "Customer Bank Name"},
    "customer_current_employer_name": {name: "Customer Current Employer Name"},
    "customer_current_employer_address": {name: "Customer Current Employer Address"},
    "customer_current_employer_status": {name: "Customer Current Employment Status"},
    "customer_current_employer_occupation": {name: "Customer Current Occupation"},
    "customer_current_employer_income_type": {name: "Customer Current Income Type"},
    "customer_current_monthly_income": {name: "Customer Current Monthly Income"},
    "customer_previous_employer_name": {name: "Customer Previous Employer Name"},
    "customer_previous_employer_address": {name: "Customer Previous Employer Address"},
    "customer_previous_employer_status": {name: "Customer Previous Employment Status"},
    "customer_previous_employer_occupation": {name: "Customer Previous Occupation"},
    "customer_previous_employer_income_type": {name: "Customer Previous Income Type"},
    "customer_previous_monthly_income": {name: "Customer Previous Monthly Income"},
    "customer_reported_income": {name: "Customer Calculated Monthly income"},
    "customer_other_income": {name: "Customer Other Income"},
    "customer_other_income_source": {name: "Customer Other Income Source"}    
}
config.customer.idTypes = {
    id_driverlicense: "DL",
    id_passport: "US Passport",
    id_card: "US ID Card",
    id_foreign: "Foreign DL/ID/Passport"
}
config.customer.entityTypes = {
    "": "",
    type_solepartnership: "Sole Partnership",
    type_partnership: "Partnership",
    type_corporation: "Corporation",
    type_solecorporation: "Sole Coreporation",
    type_llc: "LLC",
}
config.customer.relationshipTypes = {
    reference_relationship_relative: "Relative",
    reference_relationship_friend: "Friend",
    reference_relationship_collegue: "Collegue"
}
config.customer.incomeTypes = {
    employment_income_w2: "W2",
    employment_income_selfemployed: "Self Employed",
    employment_income_cash: "Cash Income",
    employment_income_military: "Military"
}
config.customer.employmentTypes = {
    employment_status_fulltime: "Employed - Full Time",
    employment_status_parttime: "Employed - Part Time",
    employment_status_temporary: "Temporary/Seasonal",
    employment_status_activemilitary: "Active Military",
    employment_status_ssiretired: "SSI/Retired",
    employment_status_selfemployed: "Self Employed",
    employment_status_unemployed: "Unemployed",
    employment_status_other: "Other"
}
config.customer.addressTypes = {
    address_type_mortgage: "Mortgage",
    address_type_rent: "Rent",
    address_type_family: "Family",
    address_type_ownoutright: "Own Outright",
    address_type_military: "Military",
    address_type_other: "Other"
}
config.customer.referencesValidationThreshold = 3
config.customer.clearDealFields = ["customer_firstname", "customer_lastname", "credit_score", "credit_report_time"]

config.vehicle = {}
config.vehicle.fields = {
    "vehicle_vin": {name: "Vehicle VIN"},
    "vehicle_year": {name: "Vehicle Year", format: ""},
    "vehicle_mark": {name: "Vehicle Make"},
    "vehicle_model": {name: "Vehicle Model"},
    "vehicle_trim": {name: "Vehicle Trim"},
    "vehicle_body": {name: "Vehicle Body"},
    "vehicle_fueltype": {name: "Vehicle Fuel Type"},
    "vehicle_cost": {name: "Vehicle Cost"},
    "vehicle_stock": {name: "Vehicle Stock Number"},
}
config.vehicle.clearDealFields = ["vehicle_mark", "vehicle_year", "vehicle_model", "vehicle_year", "vehicle_vin"]

config.docusign = {}
config.docusign.emailSubject = "Documents for Signing"
config.docusign.inPersonHostName = "Vitaly Krugliakov"
config.docusign.inPersonHostEmail = "sc@scalecapitalco.com"

config.tickets = {}
config.tickets.subjectMinLength = 5
config.tickets.subjectMaxLength = 40
config.tickets.textMinLength = 10
config.tickets.textMaxLength = 150

config.time = {}
config.time.secondsInHour = 3600
config.time.secondsInDay = 86400

config.shared = {}
config.shared.mobileWidthBreakpoint = 800
config.shared.confirmationLocale = {
    confirm: {
        title: "Confirm your account",
        context: {
            text1: `We have recently received a request to confirm your account. In order to complete this process, please click on the following link: %domainName%/confirm/%urlId%. Once you have accessed provided link, your account will be successfully confirmed!`,
            text2: "Please note that this is a standard security procedure to ensure the integrity and safety of your account. Your prompt attention to this matter is greatly appreciated.",
            text3: "If you have any concerns or did not initiate this request, please contact our support team immediately."
        }
    },
    password: {
        title: "Password change request",
        context: {
            text1: `We have received a request to change the password associated with your account. In order to complete this process, please click on the following link: %domainName%/account/newpassword/%urlId%.\nOnce you have accessed the provided link, you will be prompted to enter and confirm your new password.`,
            text2: "For security purposes, please ensure that you choose a strong and unique password that is known only to you. If you did not initiate this request or believe it to be an error, please contact our support team immediately.",
            text3: "Thank you for your attention to this matter."
        }
    }
}
config.shared.stateCodesMap = {
    state_none: "",
    state_international: "International",
    state_al: "AL",
    state_ak: "AK",
    state_ar: "AR",
    state_az: "AZ",
    state_ca: "CA",
    state_co: "CO",
    state_ct: "CT",
    state_dc: "DC",
    state_de: "DE",
    state_fl: "FL",
    state_ga: "GA",
    state_hi: "HI",
    state_id: "ID",
    state_il: "IL",
    state_in: "IN",
    state_ia: "IA",
    state_ks: "KS",
    state_ky: "KY",
    state_la: "LA",
    state_me: "ME",
    state_md: "MD",
    state_ma: "MA",
    state_mi: "MI",
    state_mn: "MN",
    state_ms: "MS",
    state_mo: "MO",
    state_mt: "MT",
    state_ne: "NE",
    state_nv: "NV",
    state_nh: "NH",
    state_nj: "NJ",
    state_nm: "NM",
    state_ny: "NY",
    state_nc: "NC",
    state_nd: "ND",
    state_oh: "OH",
    state_ok: "OK",
    state_or: "OR",
    state_pa: "PA",
    state_ri: "RI",
    state_sc: "SC",
    state_sd: "SD",
    state_tn: "TN",
    state_tx: "TX",
    state_ut: "UT",
    state_vt: "VT",
    state_va: "VA",
    state_wa: "WA",
    state_wv: "WV",
    state_wi: "WI",
    state_wy: "WY",
}

config.shared.stateCodesMapSwapped = {
    "": "state_none",
    "Alaska": "state_ak",
    "Colorado": "state_co",
    "Georgia": "state_ga",
    "Indiana": "state_in",
    "Maryland": "state_md",
    "Mississippi": "state_ms",
    "New Hampshire": "state_nh",
    "Ohio": "state_oh",
    "South Carolina": "state_sc",
    "Virginia": "state_va",
    "Wyoming": "state_wy",
    "Alabama": "state_al",
    "Connecticut": "state_ct",
    "Hawaii": "state_hi",
    "Kansas": "state_ks",
    "Maine": "state_me",
    "Montana": "state_mt",
    "New Jersey": "state_nj",
    "Oklahoma": "state_ok",
    "South Dakota": "state_sd",
    "Vermont": "state_vt",
    "Arkansas": "state_ar",
    "District Of Columbia": "state_dc",
    "Iowa": "state_ia",
    "Kentucky": "state_ky",
    "Michigan": "state_mi",
    "North Carolina": "state_nc",
    "New Mexico": "state_nm",
    "Oregon": "state_or",
    "Tennessee": "state_tn",
    "Washington": "state_wa",
    "Arizona": "state_az",
    "Delaware": "state_de",
    "Idaho": "state_id",
    "Lousiana": "state_la",
    "Minnesota": "state_mn",
    "North Dacota": "state_nd",
    "Nevada": "state_nv",
    "Pennsylvania": "state_pa",
    "Texas": "state_tx",
    "Wisconsin": "state_wi",
    "California": "state_ca",
    "Florida": "state_fl",
    "Illinois": "state_il",
    "Massachusetts": "state_ma",
    "Missouri": "state_mo",
    "Nebraska": "state_ne",
    "New York": "state_ny",
    "Rhode Island": "state_ri",
    "Utah": "state_ut",
    "West Virginia": "state_wv",

    "AK": "state_ak",
    "CO": "state_co",
    "GA": "state_ga",
    "IN": "state_in",
    "MD": "state_md",
    "MS": "state_ms",
    "NH": "state_nh",
    "OH": "state_oh",
    "SC": "state_sc",
    "VA": "state_va",
    "WY": "state_wy",
    "AL": "state_al",
    "CT": "state_ct",
    "HI": "state_hi",
    "KS": "state_ks",
    "ME": "state_me",
    "MT": "state_mt",
    "NJ": "state_nj",
    "OK": "state_ok",
    "SD": "state_sd",
    "VT": "state_vt",
    "AR": "state_ar",
    "DC": "state_dc",
    "IA": "state_ia",
    "KY": "state_ky",
    "MI": "state_mi",
    "NC": "state_nc",
    "NM": "state_nm",
    "OR": "state_or",
    "TN": "state_tn",
    "WA": "state_wa",
    "AZ": "state_az",
    "DE": "state_de",
    "ID": "state_id",
    "LA": "state_la",
    "MN": "state_mn",
    "ND": "state_nd",
    "NV": "state_nv",
    "PA": "state_pa",
    "TX": "state_tx",
    "WI": "state_wi",
    "CA": "state_ca",
    "FL": "state_fl",
    "IL": "state_il",
    "MA": "state_ma",
    "MO": "state_mo",
    "NE": "state_ne",
    "NY": "state_ny",
    "RI": "state_ri",
    "UT": "state_ut",
    "WV": "state_wv",
}

config.shared.helpTooltips = {
    HELP_TEXT_REFERENCE: `Account reference changes what account should be used in <b>Contracts</b> and <b>E-Sign processing</b>`,
    HELP_TEXT_AUTOSTRUCTURE: `Allows user to perform autostructure (lenders have this function by default)`,
    HELP_TEXT_SUPERUSER: `Allows user to perform actions without required deal processing`,
    HELP_TEXT_STRIPE: `This category is required for <b>Stripe</b>`,
    HELP_TEXT_VALUATION: `In order to update valuation, click <b>Update Book Values</b> sidebar button`
}

config.shared.extensionsWhitelist = ["txt", "png", "jpg", "tiff", "jpeg", "pdf", "doc", "docx", "csv", "xlsx"]
config.shared.extensionsForImages = ["png", "jpg", "tiff", "jpeg"]
config.shared.checkExtensionWhitelist = (fileName) => {
    const div = fileName.split(".")
    const ext = div[div.length - 1].toLowerCase()
    return config.shared.extensionsWhitelist.includes(ext)
}

config.shared.colorPalette = '#FF0000 #0000FF #00FF00 #000000 #FFFFFF #FFFF00 #CC6600 #00FFFF #6600CC #FF8000 #FF007F'

config.logs = {
    TRACKER_LOG_BOARD_CREATED: "created board <b board-id='%boardId%' load-field='title'></b>",
    TRACKER_LOG_BOARD_REMOVED: "removed board <b board-id='%boardId%' load-field='title'></b>",
    TRACKER_LOG_BOARD_RESTORED: "restored board <b board-id='%boardId%' load-field='title'></b>",
    TRACKER_LOG_BOARD_CHANGED_NAME: "changed board name from <b>%boardNamePrev%</b> to <b>%boardName%</b>",

    TRACKER_LOG_TASK_CREATED: "created task <b task-id='%taskId%' load-field='title'></b>",
    TRACKER_LOG_TASK_REMOVED: "removed task <b task-id='%taskId%' load-field='title'></b>",
    TRACKER_LOG_TASK_RESTORED: "restored task <b task-id='%taskId%' load-field='title'></b>",
    TRACKER_LOG_TASK_CHANGED_NAME: "changed task name from <b>%taskNamePrev%</b> to <b task-id='%taskId%'>%taskName%</b>",
    TRACKER_LOG_TASK_CHANGED_STATUS: "changed task status of <b task-id='%taskId%' load-field='title'></b> from <b>%taskStatusPrev%</b> to <b>%taskStatus%</b>",
    TRACKER_LOG_TASK_CHANGED_DESCRIPTION: "changed task <b task-id='%taskId%' load-field='title'></b> description",
    TRACKER_LOG_TASK_ATTACHMENT_ADDED: "added attachment <b>%attachmentName%</b> to task <b task-id='%taskId%' load-field='title'></b>",
    TRACKER_LOG_TASK_ATTACHMENT_REMOVED: "removed attachment <b>%attachmentName%</b> from task <b task-id='%taskId%' load-field='title'></b>",
    TRACKER_LOG_TASK_COMMENT_ADDED: "added comment to task <b task-id='%taskId%' load-field='title'></b>",
    TRACKER_LOG_TASK_COMMENT_REMOVED: "removed comment from task <b task-id='%taskId%' load-field='title'></b>",

    DEAL_LOG_CHANGED_STATUS: "changed deal status from <b>%statusPrev%</b> to <b>%statusCur%</b>",
    DEAL_LOG_CHANGED_SAVED: "saved deal",
    DEAL_LOG_SUBMISSION_ADDED: "<b>submitted</b> deal to <b>%lenderName%</b>",
    DEAL_LOG_SUBMISSION_CANCELLED: "<b>cancelled</b> submission",
    DEAL_LOG_SUBMISSION_APPROVED: "<b>approved</b> submission with lender <b>%lenderName%</b>",
    DEAL_LOG_GPS_CHANGED: "changed GPS status to <b>%gpsStatus%</b>",
    DEAL_LOG_COLLATERAL_ATTACHED: `added <a target="_blank" href="/%collateralSection%/%collateralId%"><b>%collateralName%</b></a>`,
    DEAL_LOG_DOCUSIGN_SEND: `sent Contracts for E-Signing`,
    DEAL_LOG_DOCUSIGN_FINISHED: `Deal is E-Signed and ready for funding`
}

config.tracker = {}
config.tracker.TASK_STATUS_DEFAULT = 0
config.tracker.TASK_STATUS_PENDING = 1
config.tracker.TASK_STATUS_WORKING = 2
config.tracker.TASK_STATUS_COMPLETED = 3
config.tracker.statusMap = {
    [config.tracker.TASK_STATUS_DEFAULT]: {
        name: "Unassigned",
        color: "#333333"
    },
    [config.tracker.TASK_STATUS_PENDING]: {
        name: "Pending",
        color: "#D2BF13"
    },
    [config.tracker.TASK_STATUS_WORKING]: {
        name: "Working",
        color: "#D49C19"
    },
    [config.tracker.TASK_STATUS_COMPLETED]: {
        name: "Finished",
        color: "#16B71C"
    },
}

config.models = {}
config.models.attachment = {
    taskId: String,
    commentId: String,
    attachmentId: String,
    name: String,
    extension: String,
    userId: Number,
    date: Number,
    isRemoved: Boolean
}
config.models.board = {
    userId: Number,
    boardId: String,
    title: String,
    date: Number,
    order: Number,
    isRemoved: {
        type: Boolean,
        default: false
    }
}
config.models.task = {
    taskId: String,
    boardId: String,
    title: String,
    status: Number,
    text: String,
    date: Number,
    completionDate: Number,
    order: Number,
    attachments: [config.models.attachment],
    isRemoved: {
        type: Boolean,
        default: false
    }
}
config.models.taskComment = {
    taskId: String,
    commentId: String,
    text: String,
    date: Number,
    userId: Number,
    attachments: [config.models.attachment],
    isRemoved: {
        type: Boolean,
        default: false
    }
}

module.exports = config