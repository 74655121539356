import component from "../modules/components.js"

const EmptyBanner = component.create({
    preRender: function({ title, text, absolutePos }) {
        $(".dashboard__empty").remove()

        return /*html*/`
            <div class="dashboard__empty${absolutePos ? " dashboard__empty-abs" : ""}">
                <div class="dashboard__empty-img"></div>
                <div class="dashboard__empty-title">${title}</div>
                <div class="dashboard__empty-text">${text || ""}</div>
            </div>
        `
    }
})

export default EmptyBanner