var hook = {
    stored: {},

    cachedIndexes: {},

    add: function(eventName, hookId, callback) {
        if (!this.stored[eventName]) {
            this.stored[eventName] = []
        }

        this.remove(eventName, hookId)

        this.stored[eventName].push({
            hook_id: hookId,
            callback: callback
        })

        if (!this.cachedIndexes[eventName]) {
            this.cachedIndexes[eventName] = {}
        }

        this.cachedIndexes[eventName][hookId] = this.stored[eventName].length - 1
    },

    get: function(eventName, hookId) {
        const events = this.stored[eventName] || []
        return events.find(item => item.hook_id === hookId)
    },

    call: function(eventName, ...args) {
        const events = this.stored[eventName] || []
        for (let i = 0; i < events.length; i++) {
            const event = events[i]

            if (event.callback) {
                event.callback(...args)
            }
        }
    },

    remove: function(eventName, hookId) {
        const events = this.stored[eventName] || []
        const result = events.filter(item => {
            return item.hook_id !== hookId
        })

        this.stored[eventName] = result

        this.cachedIndexes = {}
    }
}

export default hook