'use strict';

import component from "../modules/components.js"
import net from "../modules/network.js"
import cf from "../modules/common-func.js"
import notify from "../modules/notify.js";
import menu from "../modules/menu.js";
import config from "../../../lib/app-config.js";
import { layout } from "../modules/deal-calculator.js";

import EmptyBanner from "./EmptyBanner.js";

const dealFields = config.deal.fields

function getViewList({ response, ctx }) {
    const dealStatusMap = ctx.dealStatus,
        storage = ctx.storage;
    
        
    const data = response.context
    let items = ""

    for (let i = 0; i < data.length; i++) {
        const item = data[i]

        let buttons = ""
        let status = ""
        let statusClassName = "status-med"
        if (item.status === "deal_status_accepted" || item.status === "deal_status_countered") {
            buttons = /*html*/`
                <div class="btn btn-flex btn-nobackground action-viewform" perm="true" user-id="${item.user_id}">
                    <div class="sidebar__item-img img-default sidebar-saveclose"></div>
                    <span class="btn-text">View Offer</span>
                </div>
                <div class="btn btn-flex btn-add" perm="true" user-id="${item.user_id}">
                    <div class="sidebar__item-img img-default sidebar-submit"></div>
                    <span class="btn-text">Accept Offer</span>
                </div>
            `
            statusClassName = "status-good"
            status = "lender-approved"
        } else if (item.status === "deal_status_denied") {
            status = "lender-denied"
            statusClassName = "status-bad"
        }

        let changedValues = ""
        const fields = item.fields
        if (item.status === "deal_status_countered") {
            for (let j = 0; j < fields.length; j++) {
                const field = fields[j]
                let name = field.field
                let value = field.value
                let current = storage[field.field]
    
                const translate = dealFields[field.field]
                if (!translate) {
                    continue
                }
    
                name = translate.name
                if (typeof value === "number") {
                    if (translate.format === "%") {
                        value = cf.formatPercent(value)
                        current = cf.formatPercent(current)
                    } else if (translate.format === "") {
                        value = value
                        current = current
                    } else {
                        value = cf.formatMoney(value)
                        current = cf.formatMoney(current)
                    }
                }
                changedValues += `<div class="program__rule">${name}: ${current} -> ${value}</div>`
            }
        }

        const noResponse = item.status === "deal_status_application" || item.status === "deal_status_preapproved"
        if (fields.length === 0 || noResponse) {
            changedValues = "Awaiting Lender's response..."
        }

        const dealStatus = dealStatusMap[item.status]
        const isCountered = item.status === "deal_status_countered"

        items += /*html*/`
            <div class="lender__item ${status}">
                <div class="lender__title">
                    <span class="lender__name">${item.user_name}</span>
                    <div class="deal__status ${statusClassName}">${dealStatus}</div>
                </div>
                <div class="lender__body">
                    ${isCountered ? `
                        <div class="lender__status" id="updated-values">Countered values</div>
                        <div class="program__rules">
                            ${changedValues}
                        </div>
                        ` : ``}
                    
                    ${buttons !== "" ? `
                        <div class="lender__footer">
                            ${buttons}
                        </div>
                        ` : ""}
                </div>
            </div>
        `
    }

    return items
}

function getManageList({ response }) {
    let items = ""
    const payload = response.payload
    const context = response.context.map(e => e.user_id)

    if (payload.length === 0) {
        return EmptyBanner.render({ title: "No Lender has approved you yet", text: "Before you'll start processing deals, you need to make contacts with lenders" })
    }

    for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        const userId = item.user_id
        if (context.indexOf(userId) !== -1) continue

        items += /*html*/`
            <div class="contract-item" user-id="${userId}">
                <div class="checkbox sz-sm" style="margin-bottom: 0;">
                    <ctm-checkbox name="${userId}" class="checkbox__element print-checkbox" />
                </div>
                <div class="contract__item sz-md">#${item.user_id}</div>
                <div class="contract__item sz-xxl">${item.user_name}</div>
            </div>
        `
    }

    return items
}

const Lenders = component.create({
    preRender: async function(options) {
        if (options.viewType === "view") {
            return /*html*/`
                <div class="decisions">
                    <div class="decisions__header">
                        <span class="decisions__header-title">Lenders' Decisions</span>
                        <div class="decisions__header-actions">
                            <div class="btn btn-flex btn-remove" id="deny-lender" perm="true">
                                <div class="sidebar__item-img img-default sidebar-deny"></div>
                                <span class="btn-text">Cancel Submission</span>
                            </div>
                        </div>
                    </div>

                    <div class="decisions__list" id="decisions">
                        ${getViewList(options)}
                    </div>
                </div>
            `
        } else {
            const isEmpty = options.response.payload.length === 0

            return /*html*/`
                <div class="contracts">
                    ${isEmpty ? '' : `
                        <div class="contracts__header">
                            <div class="btn btn-nobackground sz-sm" id="btn-toggle"></div>
                            <div class="contract__item sz-md">Lender ID</div>
                            <div class="contract__item sz-xxl">Lender Name</div>
                        </div>
                    `}
                    <div class="contracts-list">
                        ${getManageList(options)}
                    </div>

                    <div class="contracts-footer" style="padding: 0;">
                        ${isEmpty ? '' : `
                            <div class="btn btn-flex" id="submit-lender">
                                <div class="sidebar__item-img img-default sidebar-docusign"></div>
                                <span class="btn-text">Submit to Lender(s)</span>
                            </div>    
                        `}
                    </div>
                </div>
            `
        }
    },

    postRender: async function({ ctx, response }) {
        const storage = ctx.storage
        const dealId = storage.deal_id
        const dealType = storage.deal_type
        const absUrl = "/deals/" + dealId + "/lender"

        $(".btn-add").on("click", async function() {
            net.load()
            const res = await net.request("POST",  absUrl + "?user_id=" + $(this).attr("user-id"))
            net.finishLoad()
            if (res.error) {
                notify.error(res.error, 7)
                return
            }

            window.location.replace(window.location.href)
        })

        $(".action-viewform").on("click", async function() {
            const lenderId = Number($(this).attr("user-id"))
            menu.create("Offer for Deal #" + dealId, null, null, () => {
                ctx.viewOffer = false
                return true
            })
            menu.setWindowClass("window-huge")

            net.load()
            const DealCalculator = await net.template("deal/Layout")
            menu.setHTML(`
                <div class="formviewer">
                    ${DealCalculator}
                </div>
            `)
            net.finishLoad()
                
            const section = $(".calc-wrapper").last()
            section.css("width", "50%")
            $(".advanced-wrapper").last().css("width", "50%")
            $(".summary-wrapper").last().remove()

            $(".popup-element").css("z-index", "20")

            layout.loader(dealType, "shared")
            layout.loader(dealType, "category_deal")
            section.append(`<div style="margin: 20px 0; opacity: 0;">kek</div>`)

            const item = response.context.find(item => item.user_id === lenderId)

            ctx.viewOffer = true

            ctx.storage.lender_name = item.user_name

            if (item.status === "deal_status_countered") {
                for (let i = 0; i < item.fields.length; i++) {
                    const field = item.fields[i]
                    const key = field.field

                    ctx.storage[key] = field.value
                }
            }

            ctx.normalizeInputs(null, true)
            ctx.hookPopup(null, true)
        })

        $("#submit-lender").on("click", async () => {
            let counter = 0
            const lenders = {}

            $(".print-checkbox").each(function() {
                if (!$(this).prop("checked")) {
                    return
                }

                lenders[$(this).attr("name")] = true
                counter++
            })

            if (counter <= 0) { 
                notify.error("You have to select at least one Lender!")
                return
            }

            await ctx.save(null, null, true)

            net.load()

            const res = await net.json("POST", absUrl, {
                payload: lenders
            })

            if (res.error && typeof res.error !== "undefined") {
                notify.error(res.error, 10)
                net.finishLoad()
            } else {
                window.location.replace(window.location.href)
            }
        })

        $("#deny-lender").on("click", async () => {
            ctx.onRemovePressed(absUrl + "?abort=abort", window.location.href)
        })
    }
})

export default Lenders