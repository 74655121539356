import timer from "./timer.js"
import notify from "./notify.js"
import net from "./network.js"
import menu from "./menu.js"
import selection from "./selection.js"

import config, { time } from "../../../lib/app-config.js"

import Scrollbar from "smooth-scrollbar"

const cf = {}
const nf = new Intl.NumberFormat("en-US")

/**
 * Function takes an object and returns a string that can be used as URL params.
 * It will look for existing URL params and override them with the ones from the object.
 * If the value is not specified in the object, function will use the value from the URL params or a default value.
 * If "indirect" is true, function will not use the default value, instead it will return an empty string.
 * @param {Object} data - object with URL params
 * @param {Boolean} indirect - boolean that specifies whether to use default value or not
 * @returns {String} - string that can be used as URL params
 */
export function getCommonParams(data, indirect) {
    if (typeof data !== "object") {
        data = {}
    }

    const urlParams = new URLSearchParams(window.location.search)
    const incomingParamsMap = [
        {id: "search", default: ""},
        {id: "sort_id", default: ""},
        {id: "sort_order", default: ""},
        {id: "deal_type", default: ""},
        {id: "filter", default: ""},
        {id: "max_items", default: ""},
        {id: "page", default: ""},
        {id: "make", default: null},
        {id: "model", default: null},
        {id: "viewer", default: ""},
        {id: "fieldId", default: ""},
        {id: "user_id", default: ""},
        {id: "hide_cancelled", default: ""},
        {id: "display", default: ""},
    ]
    
    let str = ""

    incomingParamsMap.map(item => {
        const defaultValue = urlParams.get(item.id) || item.default
        const paramValue = typeof data[item.id] === "undefined" ? (indirect ? "" : defaultValue) : data[item.id]
        if (paramValue) {
            if (str === "") {
                str = `?${item.id}=${paramValue}`
            } else {
                str += `&${item.id}=${paramValue}`
            }
        }
    })

    return str
}
cf.getCommonParams = getCommonParams

// don't know if i use this method anywhere right now, because getCommonParams() is sufficient enough and it works just fine
// still it may be used and come in handy in the future, so i will leave it here
export function addParameter(url, param) {
    if (url.includes('?')) {
        return url + "&" + param
    } else{
        return url + "?" + param
    }
}
cf.addParameter = addParameter

// proper way is to inject round method in Number prototype, but at this point there are so much instances of this function that i do not want to remake it
export function round(value, decimals) {
    value = Number(value)
    
    const roundValue = Math.pow(10, decimals || 0)
    return Math.round(value * roundValue) / roundValue
}
cf.round = round

export function randomNumber(min, max) {
    return Math.random() * (max - min) + min;
}
cf.randomNumber = randomNumber

export function formatNumber(amount) {
    amount = Number(amount) || 0
    return nf.format(amount.toFixed(2))
}

cf.formatNumber = formatNumber

export function formatMoney(amount) {
    amount = Number(amount) || 0
    let val = "$" +  nf.format(amount.toFixed(2))
    if (amount < 0) {
        val = "-$" + nf.format(-amount.toFixed(2))
    }

    return val
}

cf.formatMoney = formatMoney

export function formatPercent(amount) {
    amount = Number(amount) || 0
    return String(amount.toFixed(3)) + "%"
}

cf.formatPercent = formatPercent

// this function is a basis for all UI positioning inside popups and sub-scrollable elements. It works the way it works, and i do not accept any criticism for it (09/16/24)
export function getCurrentUIOffset(element) {
    const parents = element.parents()
    for (let i = 0; i < parents.length; i++) {
        const className = parents[i].className

        const headerHeight = $("header").outerHeight(true)

        if (className.search("panel__list") !== -1 || className.search("list__wrapper") !== -1) {
            return [($(window).width() - $(".window__panel").width()) / 2, $(".list__wrapper").scrollTop() - $(".panel__header").outerHeight(true) - ($(window).height() - $(".window__panel").height()) / 2 - $(document).scrollTop()]
        }
        
        if (className.search("block-scroll") !== -1) {
            const scrollProgress = $(".block-scroll").scrollTop()
            return [$(".categories").width(), scrollProgress - headerHeight - $(".program-wrapper").outerHeight(true)]
        }
    }

    return [0, 0]
}
cf.getCurrentUIOffset = getCurrentUIOffset

export function trackInput(input, condition, text, init) {
    const tracker = (elem, after) => {
        let parent = elem.parent(".popup__input")
        if (parent.length === 0) {
            parent = null
        }

        const inputValue = elem.val()
        const result = condition(after ? (elem.attr("value") || inputValue) : inputValue, elem)
        if (typeof result === "string") {
            text = result
        }

        if (result) {
            if (!after) return

            addInputError(elem, text)
        } else {

            removeInputError(elem)
        }
    }

    if (init) {
        input.each(function() {
            tracker($(this), true)
        })
    }

    input.on("input", function(e, after) {
        tracker($(this), after)
    })

    input.on("blur", function() {
        timer.simple(0.2, () => {
            tracker($(this), true)
        })
    })
}
cf.trackInput = trackInput

// all inputs are basically wrapped in popup__input element, but sometimes they can be nested in input-wrap element, when we need additional division of input and button(s)
const _getErrorParent = (element) => {
    let parent = element.parent(".input")
    let secondParent = element.parent(".input-wrap").parent(".input")
    if (secondParent[0]) {
        parent = secondParent
    }

    if (parent.length === 0) {
        parent = null
    }

    return parent
}

export function addInputError(element, text) {
    const parent = _getErrorParent(element)
    const warningClass = "input-warning"

    if (element.hasClass(warningClass)) {
        parent.children(".error-notice").html(text)
    } else {
        element.addClass(warningClass)

        if (text && parent) {
            if (parent) {
                parent.append(`
                    <span class="error-notice">${text}</span>
                `)
            } else {
                notify.error(text)
            }
        }
    }
}
cf.addInputError = addInputError

export function removeInputError(element) {
    const parent = _getErrorParent(element)
    element.removeClass("input-warning")
    if (parent) {
        parent.children(".error-notice").remove()
    }
}
cf.removeInputError = removeInputError

export function validateForm() {
    $("input").each(function(){
        $(this).trigger("input", [true])
    })
}
cf.validateForm = validateForm

export function formHasError(warningMsg) {
    validateForm()

    warningMsg = warningMsg || "All fields should be correct before saving changes!"
        
    let hasError = false
    $(".wrapper__input").each(function() {
        if ($(this).hasClass("input-warning")) {
            if (!hasError) {
                notify.error(warningMsg)
            }
            hasError = true
            return
        }
    })

    return hasError
}
cf.formHasError = formHasError

export function emailTrackerFunc(value, element) {
    value = value.replaceAll(/[^a-zA-Z.@0-9]/g, "")

    if (value === "") {
        return false
    }

    const words = value.split(" ")
    if (words.length > 1) {
        return "Incorrect email format"
    }

    let spl = value.split("@")
    if (spl.length !== 2 || spl[0].trim() === "" || spl[1].trim() === "") {
        return "Missing email domain (@example)"
    }

    const address = spl[1].split(".")
    if (address.length < 2 || address[0].trim() === "" || address[1].trim() === "") {
        return "Missing domain zone (.example)"
    }

    return false
}
cf.emailTrackerFunc = emailTrackerFunc

export function trackEmailAndPhones() {
    trackInput($("input[email=true], #recovery_email"), (value, element) => {
        return emailTrackerFunc(value, element)
    }, "Incorrect email format!")
    
    trackInput($("input[phone=true]"), (value, element) => {
        const realValue = value.replace(/\D/g, "")
        const length = realValue.length
        
        element.val(formatPhoneNumber(realValue))

        element.prop("lastLength", length)

        if (realValue === "") {
            return false
        }

        if (length < 10) {
            return "There is less than 10 digits in this phone number!"
        }

        return false
    }, "Incorrect phone format!")
}
cf.trackEmailAndPhones = trackEmailAndPhones

export function formatPhoneNumber(phone) {
    if (phone == null || phone == "") {
        return "-";
    }

    const phoneData = String(phone).match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    const dash = phoneData[3] ? "-" : ""
    const closingBracket = phoneData[2] ? ") " : ""
    const phoneStr = `(${phoneData[1]}${closingBracket}${phoneData[2]}${dash}${phoneData[3]}`

    return phoneStr
}
cf.formatPhoneNumber = formatPhoneNumber

export function formatDate(dateValue) {
    return dateValue > 9 ? dateValue : "0" + String(dateValue)
}
cf.formatDate = formatDate

export function formatInputDate(date) {
    const dateObj = new Date(date)
    return formatDate(dateObj.getMonth() + 1) + "/" + formatDate(dateObj.getDate()) + "/" + dateObj.getFullYear()
}
cf.formatInputDate = formatInputDate

export function formatHumanDate(date, fullMonth) {
    const humanMonths = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ]

    let dateObj = typeof date === "number" ? new Date(date) : date

    if (!dateObj) return

    const month = fullMonth ? humanMonths[dateObj.getMonth()] : humanMonths[dateObj.getMonth()].substring(0, 3)

    return dateObj.getDate() + " " + month + " " + dateObj.getFullYear()
}
cf.formatHumanDate = formatHumanDate

export function customScrollbarTracker() {
    // Custom scroll event tracker
    const scrollbarDOM = $(".scrollbar-thumb-y")[0]
    if (scrollbarDOM) {
        new MutationObserver(() => {
            $(".popup-selection").remove()
        }).observe(scrollbarDOM, {attributes: true, attributeFilter: ["style"]})
    }
}
cf.customScrollbarTracker = customScrollbarTracker

export function createListInteraction(route, buttonId) {
    jQuery(() => {
        $(".deals__deal").on("click", function() {
            window.location.replace(route + "/" + $(this).attr("data-id"))
        })

        $(buttonId).on("click", async () => {
            const res = await net.request("POST", route)
            window.location.replace(route + "/" + res.payload)
        })

        setupMainTableDisplay()
        customScrollbarTracker()
    })
}
cf.createListInteraction = createListInteraction

export function prepareMainListSize() {
    timer.simple(0.5, () => {
        const dealsList = $('.deals-list')
        const dealsHeader = $(".deals-header")
    
        let dealsHeaderHeight = 0
        if (dealsHeader.length > 0) {
            dealsHeaderHeight = dealsHeader.outerHeight()
        }
    
        if (dealsList.length > 0) {
            Scrollbar.init(dealsList[0], {
                alwaysShowTracks: true
            })
    
            const height = $(window).height() - $("header").outerHeight() - dealsHeaderHeight - $(".deals-title").outerHeight() - $("#paginator").outerHeight() - 2
    
            dealsList.css("height", height)
        }
    
        adjustColumnValuesSizeToHeaders()
    })
}
cf.prepareMainListSize = prepareMainListSize

export function setupMainTableDisplay(account) {
    $(".deals__deal").on("contextmenu", function(event) {
        if ($(this).attr("plain")) return

        const itemId = $(this).attr("data-id")
        const dealStatus = $(this).children(".deal__status").attr("status")
        const pathName = window.location.href

        const options = [
            {
                name: "Duplicate",
                icon: "icon-duplicate",
                canShow: () => {
                    if (!dealStatus) return true
                    return dealStatus === "deal_status_init"
                },
                callback: async () => {
                    await net.request("POST", `${pathName}/${itemId}/duplicate`)
                    window.location.replace(window.location.href)
                }
            },

            {
                name: "Remove",
                icon: "sidebar-remove",
                canShow: () => {
                    if (!dealStatus) return true
                    return account?.user_type === "type_root" || dealStatus === "deal_status_init"
                },
                callback: () => {
                    onRemovePressed(`${pathName}/${itemId}`, pathName)
                }
            }
        ]

        const createdOptions = selection.create($(".wrapper"), true, options)

        if (createdOptions > 0) {
            event.preventDefault()
        }
    })

    $("[date-time]").each(function() {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        const date = new Date(Number($(this).attr("date-time")))

        $(this).html(date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear())
    })

    prepareMainListSize()

    $(window).on("resize", () => {
        prepareMainListSize()
    })
}

cf.setupMainTableDisplay = setupMainTableDisplay

export function adjustColumnValuesSizeToHeaders() {
    $(".header__name[refer]").each(function() {
        const listHeader = $(".header__name[type=" + $(this).attr("refer") + "]")[0]
        if (!listHeader) return

        const getColumnSize = listHeader.className.match(/sz-\w*/g)
        $(this).addClass(getColumnSize[0])
    })
}

cf.adjustColumnValuesSizeToHeaders = adjustColumnValuesSizeToHeaders

export async function onRemovePressed(endpoint, urlOrCallback, overrideMethod, onLoad) {
    net.load()
    const html = await net.template("DeleteWarning", {
        yesText: onLoad ? "Confirm" : "Yes",
        noText: onLoad ? "Cancel" : "No"
    })

    net.finishLoad()

    menu.create("Confirm Action", true)
    menu.setWindowClass("window-auto", true)
    menu.setHTML(html, true)
    
    const yesButton = $(".confirm__yes")
    yesButton.on("click", async () => {
        if (yesButton.attr("disabled")) {
            return
        }

        net.load()

        let route = endpoint.search("delete") !== -1 ? "" : "/remove"
        if (overrideMethod) route = ""

        await net.request(overrideMethod || "POST", endpoint + route)

        if (typeof urlOrCallback === "function") {
            urlOrCallback()
        } else {
            window.location.replace(urlOrCallback)
        }

        menu.close("-2")
    })
    
    if (onLoad) {
        onLoad()
    }

    $(".confirm__no").on("click", () => {
        menu.close("-2")
    })
}
cf.onRemovePressed = onRemovePressed

export function isEqualDates(a, b) {
    return (a.getMonth() === b.getMonth() && a.getDate() === b.getDate() && a.getFullYear() === b.getFullYear())
}
cf.isEqualDates = isEqualDates

export function capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
cf.capitalizeString = capitalizeString

export function pushParam(id, value) {
    const uri = window.location.pathname
    const params = new URLSearchParams(window.location.search)
    if (params.has(id)) {
        params.set(id, value)
    } else {
        params.append(id, value)
    }

    const paramsStr = params.toString()
    window.history.pushState("", "", uri + (paramsStr === "" ? "" : "?") + paramsStr)
}
cf.pushParam = pushParam

export function revokeParam(id) {
    const uri = window.location.pathname
    const params = new URLSearchParams(window.location.search)
    params.delete(id)

    const paramsStr = params.toString()
    window.history.pushState("", "", uri + (paramsStr === "" ? "" : "?") + paramsStr)
}
cf.revokeParam = revokeParam

export function resetAllParams() {
    window.history.pushState("", "", window.location.pathname)
}
cf.resetAllParams = resetAllParams

export function hookImageClickables() {
    const body = $("body")

    $("[data-image]").off("click").on("click", function() {
        const src = $(this).children("img").attr("src")

        let index = Number(src.substring(src.length - 1, src.length))
        let scale = $(this).prop("scaler") || 1

        body.css("overflow", "hidden")

        body.append(/*html*/`
            <div class="image-viewer">
                <div class="image-wrapper" id="img-wrapper">
                    <img id="img-element" src="${src}" index="${index}">
                </div>
                
                ${$(this).attr("data-index") ? /*html*/`
                    <div class="controller">
                        <div class="controller-btn controller-left">
                            <
                        </div>
                        <div id="image-index">${index + 1}</div>
                        <div class="controller-btn controller-right">
                            >
                        </div>
                    </div>    
                ` : ""}
            </div>
        `)

        const imgWrapper = $("#img-wrapper")
        const controller = $(".controller")
        const controllerBtn = $(".controller-btn")
        const imgViewer = $(".image-viewer")
        const imgElement = $("#img-element")
        const imgIndexElement = $("#image-index")

        imgWrapper.draggable()
        imgViewer.trigger("wheel", [true])
        imgElement.css("transform", "scale(" + scale + ")")

        controller.on("click", (event) => {
            event.stopPropagation()
        })

        imgViewer.on("wheel", (event) => {
            if (event.originalEvent.deltaY < 0) {
                scale = scale + 0.1
            } else {
                scale = scale - 0.1
            }

            scale = Math.max(Math.min(scale, 3), 1)

            $(this).prop("scaler", scale)

            imgElement.css("transform", "scale(" + scale + ")")
        })
        imgViewer.on("click", function() {
            const duration = 0.25
            
            $(this).animate({opacity: "0"}, duration * 1000)

            timer.simple(duration, () => {
                $(this).remove()
            })

            body.css("overflow", "")
        })

        controllerBtn.on("click", function(event) {
            let move = 1
            if ($(this).hasClass("controller-left")) {
                move = -1
            }

            let newIndex = index + move
            let findElement = $(".inventory__images-item[data-index=" + newIndex + "]")
            if (findElement.length === 0) {
                return
            }

            index = newIndex

            imgElement.attr("src", src.substring(0, src.length - 1) + index)
            imgIndexElement.html(index + 1)
        })
    })
}
cf.hookImageClickables = hookImageClickables

export default cf